import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CustomBreadCrumbs from "../../../components/breadcrumbs";
import Tabs from "../../../customComponents/Tabs/Tabs";
import { getUserReportDetails } from "../apis/api.action";

export default function UserReprotDetails(props: any) {
  const params = useParams();
  const [data, setData] = useState<any>([]);
  const [workId, setWorkId] = useState(params.id);
  const [tab, setTab] = useState("userReport");

  const getData = (id: any) => {
    getUserReportDetails(id).then((res) => {
      setData(res);
    });
  };
  useEffect(() => {
    getData(workId);
  }, []);
  const tabs = [
    {
      label: "Detailed Information",
      value: "userReport",
      tagText: "Detailed Information",
    },
  ];
  return (
    <div>
      <CustomBreadCrumbs
        data={[
          { title: "Reporting", link: "/reporting-list" },
          { title: "User Reports" },
        ]}
      />
      <div className="customCardContainer bg-bgWhite !shadow-none !rounded-lg flex flex-col flex-1">
        <Tabs
          ariaLabel="consultation tabs"
          setTab={setTab}
          tab={tab}
          tabs={tabs}
        />
        <div className="content tabContent">
          <div className="flex flex-col">
            <div className="grid gap-4 p-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
              <div className="col-span-1 ">
                <div className="flex flex-col">
                  <label className="labelText">Report ID</label>
                </div>
                <div className="flex flex-col">
                  <p className="text-sm min-h-[20px]">
                    {data?.display_id ?? "--"}
                  </p>
                </div>
              </div>
              <div className="col-span-1 ">
                <div className="flex flex-col">
                  <label className="labelText">User ID</label>
                </div>
                <div className="flex flex-col">
                  <p className="text-sm min-h-[20px]">
                    {data?.user?.display_id ?? "--"}
                  </p>
                </div>
              </div>
              {/* <div className="col-span-1 ">
                <div className="flex flex-col">
                  <label className="labelText">Title</label>
                </div>
                <div className="flex flex-col">
                  <p className="text-sm min-h-[20px]">{data?.title ?? "--"}</p>
                </div>
              </div> */}
              <div className="col-span-1 ">
                <div className="flex flex-col">
                  <label className="labelText">Reason</label>
                </div>
                <div className="flex flex-col">
                  <p className="text-sm min-h-[20px]">
                    {data?.complaint?.name ?? "--"}
                  </p>
                </div>
              </div>
              <div className="col-span-1 ">
                <div className="flex flex-col">
                  <label className="labelText">Remarks</label>
                </div>
                <div className="flex flex-col">
                  <p className="text-sm min-h-[20px]">
                    {data?.description ?? "--"}
                  </p>
                </div>
              </div>
              <div className="col-span-1 ">
                <div className="flex flex-col">
                  <label className="labelText">Reported By</label>
                </div>
                <div className="flex flex-col">
                  <p className="text-sm min-h-[20px]">
                    {data?.created_by?.name ?? "--"}
                  </p>
                </div>
              </div>
              <div className="col-span-1 ">
                <div className="flex flex-col">
                  <label className="labelText"> Reported On </label>
                </div>
                <div className="flex flex-col">
                  <p className="text-sm min-h-[20px]">
                    {data?.created_at
                      ? moment(data?.created_at).format("DD-MM-YYYY")
                      : "--"}
                  </p>
                </div>
              </div>
              <div className="col-span-1 ">
                <div className="flex flex-col">
                  <label className="labelText">Resolution Status</label>
                </div>
                <div className="flex flex-col">
                  <p className="text-sm min-h-[20px]">
                    {data?.resolution_status ?? "--"}
                  </p>
                </div>
              </div>
              <div className="col-span-1 ">
                <div className="flex flex-col">
                  <label className="labelText">Resolution Comment</label>
                </div>
                <div className="flex flex-col">
                  <p className="text-sm min-h-[20px]">
                    {data?.comment ?? "--"}
                  </p>
                </div>
              </div>
              <div className="col-span-1 ">
                <div className="flex flex-col">
                  <label className="labelText"> Action Taken Date </label>
                </div>
                <div className="flex flex-col">
                  <p className="text-sm min-h-[20px]">
                    {data?.resolved_at
                      ? moment(data?.resolved_at).format("DD-MM-YYYY")
                      : "--"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
