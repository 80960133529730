import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";

import CustomBreadCrumbs from "../../components/breadcrumbs/index";
import Tabs from "../../customComponents/Tabs/Tabs";
import { useDataFieldStore } from "../../store/datafields";
import Favourite from "../../components/favourite/index";
import moment from "moment";
import { getPurchaseDetails } from "./apis/api.actions";
import { capitalize } from "../../utilities/format";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function PurchaseDetailedView(props: any) {
  const { datafieldsItem, setDataFieldsitem } = useDataFieldStore();
  const params = useParams();
  const navigate = useNavigate();

  const [tab, setTab] = useState("purchaseDetails");
  const [data, setData] = useState<any>([]);
  const [favouriteData, setFavoriteData] = useState<any>([]);

  const [purchaseId, setPurchaseId] = useState(params.id);
  const tabs = [
    {
      label: "Detailed Information",
      value: "purchaseDetails",
      tagText: "Detailed Information",
    },
  ];

  const getData = (id: any) => {
    getPurchaseDetails(id).then((res) => {
      setData(res);
    });
  };

  const handleEntityLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    if (row.entity?.id) {
      setDataFieldsitem({
        name: row.entity?.name,
        key: row.entity?.id,
      });

      navigate(`/${row.purchased_by_type}/${row.entity?.id}`);
    }
  };

  useEffect(() => {
    getData(purchaseId);
  }, []);

  return (
    <div>
      <CustomBreadCrumbs
        data={[
          { title: "Plan & Add-on Sale", link: "/purchase-list" },
          { title: datafieldsItem.name },
        ]}
      />
      <div className=" bg-bgWhite customCardContainer !shadow-none !rounded-lg flex flex-col flex-1">
        <Tabs
          ariaLabel="consultation tabs"
          setTab={setTab}
          tab={tab}
          tabs={tabs}
        />
        {tab === "purchaseDetails" && (
          <div className="content tabContent ">
            <div className="flex flex-col">
              <div className="p-1.5 px-3 flex items-center justify-start w-full bg-tableHeader">
                <p className="text-sm font-semibold">Purchase Details</p>
              </div>
              <div className="grid gap-4 p-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Purchased Date</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.created_at
                        ? moment(data.created_at).format("DD-MM-YYYY")
                        : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Purchased By</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {
                        data.entity ? (<Link className="hover:underline text-[#1650e2]" to={``} onClick={(e) => handleEntityLink(e, data)}>
                        {data.entity?.name}
                      </Link>) : "--"
                      }
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Item Type</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.purchased_item_type}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Item Name</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.purchased_item_type === 'Plan' ? data.plan_title : data.addon_title}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Amount Paid</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.currency ?? ""} {data.price ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Status</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.status ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Purchase ID</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.payment_session?.client_reference_id ?? "--"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
