import { getData, postData } from "../../../apis/api.helpers";
import apiUrl from "./api.url";

export const getPayoutList = (
  perPage: number,
  page: number,
  search?: string,
  filters?: string
) =>
  getData(
    `${apiUrl.PAYOUT_LIST}per_page=${perPage}&page=${page}&search=${search}${filters}`
  ).then((res) => res.data);

export const getPayoutDetails = (id: string) =>
  getData(`${apiUrl.PAYOUT_DETAILS}/${id}`).then((res) => res.data);

  export const settlePayout = (payoutId: string, payload: any) => postData(`${apiUrl.SETTLE_PAYOUT(payoutId)}`, payload);

  export const exportPayoutList = (
    type: string,
    search?: string,
    filters?: string
  ) =>
    getData(
      `${apiUrl.EXPORT_PAYOUT_LIST}bank_type=${type}&search=${search}${filters}`, {
        responseType: "blob",
      }
    );
