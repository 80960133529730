import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";

import CustomBreadCrumbs from "../../../components/breadcrumbs/index";
import Tabs from "../../../customComponents/Tabs/Tabs";
import { useDataFieldStore } from "../../../store/datafields";
import { getReviewDetails } from "../apis/api.actions";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function ProjectReviewDetailedView(props: any) {
  const { datafieldsItem, setDataFieldsitem } = useDataFieldStore();
  const params = useParams();
  const navigate = useNavigate();

  const [tab, setTab] = useState("reviewDetails");
  const [data, setData] = useState<any>([]);

  const [reviewId, setReviewId] = useState(params.id);
  const tabs = [
    { label: "Detailed Information", value: "reviewDetails", tagText: "Detailed Information" },
  ];

  const getData = (id: any) => {
    getReviewDetails(id).then((res) => {
      setData(res);
    });
  };

  const handleProjectLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    if (row.work?.id) {
      setDataFieldsitem({
        name: row.work?.title,
        key: row.work?.id,
      });

      navigate(`/project/${row.work?.id}`);
    }
  };

  const handleReviewerLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    if (row.user?.id) {
      setDataFieldsitem({
        name: row.user?.name,
        key: row.user?.id,
      });

      navigate(`/user/${row.user?.id}`);
    }
  };

  const handleRecipientLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    if (row.recipient?.id) {
      setDataFieldsitem({
        name: row.recipient?.name,
        key: row.recipient?.id,
      });

      navigate(`/user/${row.recipient?.id}`);
    }
  };

  useEffect(() => {
    getData(reviewId);
  }, []);
  return (
    <div>
      <CustomBreadCrumbs
        data={[
          { title: "Reviews & Ratings", link: "/manage-reviews-list-admin" },
          { title: datafieldsItem.name },
        ]}
      />

      <div className="customCardContainer bg-bgWhite !shadow-none !rounded-lg flex flex-col flex-1">
        <Tabs
          ariaLabel="consultation tabs"
          setTab={setTab}
          tab={tab}
          tabs={tabs}
        />

        {tab == "reviewDetails" && (
          <div className="content tabContent">
            <div className="flex flex-col">
              <div className="grid gap-4 p-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Project ID</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data?.work?.display_id ? (
                        <Link className="hover:underline text-[#1650e2]" to={``} onClick={(e) => handleProjectLink(e, data)}>
                        {data.work?.display_id}
                      </Link>
                      ) : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Review By</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data?.user_type === "Owner" ? "Owner" : "Consultant"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Reviewer</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data.user?.name ? (
                        <Link className="hover:underline text-[#1650e2]" to={``} onClick={(e) => handleReviewerLink(e, data)}>
                        {data.user?.name}
                      </Link>
                      ) : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Recipient</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data.recipient?.name ? (
                        <Link className="hover:underline text-[#1650e2]" to={``} onClick={(e) => handleRecipientLink(e, data)}>
                        {data.recipient?.name}
                      </Link>
                      ) : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Rating Given</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data?.rating ?? "--"}/5
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Review</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data?.comment ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Review Date</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data?.created_at ? moment(data?.created_at).format("DD-MM-YYYY") : "--"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
