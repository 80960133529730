import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import React,{ useState } from "react";
import { AiOutlineBars, AiOutlineCalendar, AiOutlineShoppingCart } from "react-icons/ai";
import { BiBuildings, BiMessageDetail, BiTransfer, BiUserCircle } from "react-icons/bi";
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { FaHandshake, FaHandshakeSlash } from "react-icons/fa";
import { GoReport } from "react-icons/go";
import { MdLanguage, MdOutlineDashboard, MdOutlineNextWeek, MdPayment, MdWorkOutline } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";

const SideNav = () => {
  const location = useLocation();
  console.log(location.pathname);
  const [expanded, setExpanded] = useState<boolean>(false);


  const menus = [
    {
      name: "Summary",
      link: "/dashboard",
      icon: MdOutlineDashboard,
      margin: false,
      urlMatchString: "/dashboard",
    },
    { name: "Master Data", link: "/datafields", icon: AiOutlineBars, urlMatchString: "/datafields", },
    // { name: "Language Switcher", link: "/language-switcher", icon: MdLanguage },
    { name: "Translations", link: "/language-switcher", icon: MdLanguage, urlMatchString: "/language-switcher", },
    { name: "Company", link: "/company-list", icon: BiBuildings, urlMatchString: "/company", },
    { name: "User", link: "/user-list", icon: BiUserCircle, urlMatchString: "/user", },
    // { name: "Jobs", link: "/job-list", icon: MdWorkOutline },
    { name: "Projects", link: "/project-list", icon: MdOutlineNextWeek,submenu: "project"},
    { name: "Plans", link: "/plan-list", icon: AiOutlineCalendar, urlMatchString: "/plan", },
    { name: "Add-ons", link: "/addon-list", icon: AiOutlineCalendar, urlMatchString: "/addon", },
    // { name: "Payments", link: "/payment-list", icon: MdPayment },
    { name: "Plan & Add-on Sale", link: "/purchase-list", icon: AiOutlineShoppingCart, urlMatchString: "/purchase", },
    { name: "Project Transactions", link: "/work-transaction-list", icon: BiTransfer, urlMatchString: "/work-transaction", },
    { name: "Payout Processing", link: "/payout-list", icon: FaHandshake, urlMatchString: "/payout", },
    { name: "Dispute Tickets", link: "/dispute-list", icon: FaHandshakeSlash, urlMatchString: "/dispute", },
    //{ name: "Reviews & Ratings", link: "/review-list", icon: BiMessageDetail, urlMatchString: "/review", },
    //{ name: "Reporting", link: "/reporting-list", icon: GoReport, urlMatchString: "/reporting", },
  ];

  const subMenus = [
    {id:"project", name: "Active Projects", link: "/project-list", icon: MdOutlineNextWeek, urlMatchString: "/project"},
    {id:"project", name: "Deleted Projects", link: "/deleted-project-list", icon: MdOutlineNextWeek, urlMatchString: "/deleted-project"},
  ];
  return (
    <section className="flex gap-6">
      <div
        className={`bg-primary min-h-screen w-16 hover:w-60 duration-500 text-gray-100 px-4`}
      >
        <div className="mt-4 flex flex-col gap-1 relative truncate">
          {menus?.map((menu, i) => (
            <div>
              {
               
              <Link
              onClick={(e) =>
                menu?.submenu ? setExpanded(!expanded) : ''
              }
              to={menu?.submenu ? "" : menu?.link}
              key={i}
              className={` ${
                menu?.margin && "mt-5"
              } group flex items-center text-sm  gap-3.5 font-medium p-2 ${
                menu?.urlMatchString && location.pathname.startsWith(menu?.urlMatchString) &&
                "bg-bgWhite text-primary"
              } hover:bg-gray-800 rounded-lg  text-icontextColor hover:bg-bgWhite hover:text-primary`}
            >
              <div>{React.createElement(menu?.icon, { size: "20" })}</div>
              <h2 className="overflow-hidden text-ellipsis">{menu?.name}</h2>
              {menu?.submenu && (
              <>
                {expanded ? (
                  <BiChevronUp style={{ fontSize: 24, marginLeft: '55px', float:"right" }} />
                ) : (
                  <BiChevronDown style={{ fontSize: 24, marginLeft: '55px' }} />
                )}
              </>
            )}
            </Link>
              }
            
              {menu?.submenu && expanded &&
                 <List className={`flex-1 ${expanded ? "block" : "hidden"}`}>
                 {subMenus?.map((item)=> (
                   <ListItem key={menu?.name} className="block p-0  mb-1">
                     <Link
               to={item?.link}
               key={i}
               className={` ${
                 menu?.margin && "mt-5"
               } group flex items-center text-sm  gap-3.5 font-medium p-2 ${
                 location.pathname.startsWith(item?.urlMatchString) &&
                 "bg-bgWhite text-primary"
               } hover:bg-gray-800 rounded-lg  text-icontextColor hover:bg-bgWhite hover:text-primary`}
             >
               <div>{React.createElement(item?.icon, { size: "20" })}</div>
               <h2 className="overflow-hidden text-ellipsis">{item?.name}</h2>
             </Link>
                   </ListItem>
                 ))}
               </List> 
              }
            
            </div>
           
          ))}
        </div>
      </div>
    </section>
  );
};

export default SideNav;
