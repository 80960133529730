import { zodResolver } from "@hookform/resolvers/zod";
import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";

import { changePassword } from "../../apis/auth/auth.api";
import Popup from "../../customComponents/dialogbox";
import { changePasswordSchema, ChangePasswordSchema } from "./schema";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function ChangePassword(props: Props) {
  const { open, setOpen } = props;
  const style = {
    pswWrapper: " flex relative",
    pswShow: " absolute right-0.5 z-10",
  };

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm<ChangePasswordSchema>({
    mode: "onChange",
    defaultValues: { password: "", new_password: "", confirm_password: "" },
    resolver: zodResolver(changePasswordSchema),
  });

  const { enqueueSnackbar } = useSnackbar();
  const [hidePassword, setHidePassword] = useState(true);
  const [hideNewPassword, setHideNewPassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);

  const [pswType, setPswType] = useState(true);
  const [newpswType, setNewpswType] = useState(true);
  const [conpswType, setConpswType] = useState(true);

  const handleClose = () => {
    setOpen(false);
    reset();
  };
  const onSubmit = (data: ChangePasswordSchema) => {
    changePassword(data)
      .then((res) => {
        if (res.status) {
          enqueueSnackbar("Password changed successfully", {
            variant: "success",
          });
          setOpen(false);
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
          });
        }

      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  };

  const handleView = (field: string) => {
    if (field == "password") {
      setHidePassword(!hidePassword);
      setPswType(!pswType);
    }
    if (field == "newPassword") {
      setHideNewPassword(!hideNewPassword);
      setNewpswType(!newpswType);
    }
    if (field == "confirmPassword") {
      setHideConfirmPassword(!hideConfirmPassword);
      setConpswType(!conpswType);
    }
  };

  return (
    <Popup
      open={open}
      title={"Change Password"}
      handleClose={handleClose}
      primaryButton={{
        title: "Save",
        action: () => handleSubmit(onSubmit)(),
      }}
      secondaryButton={{
        title: "Cancel",
        action: () => handleClose(),
      }}
      className="mediumDialogBox"
    >
      <div className="flex flex-col gap-4">
        <div className="relative">
          <label className="labelText" htmlFor={"email"}>
            Old Password
          </label>
          <div className={style.pswWrapper}>
            <input
              id={"password"}
              type={pswType ? "password" : "text"}
              required={true}
              className="fixedInputClass"
              placeholder={"Enter Your Old Password"}
              {...register(`password`, {
                onChange: (e) => {
                  setValue(`password`, e.target.value.trimStart());
                },
              })}
            />
            <IconButton
              className={style.pswShow}
              onClick={() => handleView("password")}
            >
              {!hidePassword ? (
                <AiFillEye className="w-5 relative bottom-1" />
              ) : (
                <AiFillEyeInvisible className="w-5 relative bottom-1" />
              )}
            </IconButton>
          </div>

          <p className="m-0 text-sm text-bgError">
            {errors?.password?.message}
          </p>
        </div>

        <div className="relative">
          <label className="labelText" htmlFor={"email"}>
            New Password
          </label>
          <div className={style.pswWrapper}>
            <input
              id={"password"}
              type={newpswType ? "password" : "text"}
              required={true}
              className="fixedInputClass"
              placeholder={"Enter Your New Password"}
              {...register(`new_password`, {
                onChange: (e) => {
                  setValue(`new_password`, e.target.value.trimStart());
                },
              })}
            />
            <IconButton
              className={style.pswShow}
              onClick={() => handleView("newPassword")}
            >
              {!hideNewPassword ? (
                <AiFillEye className="w-5 relative bottom-1" />
              ) : (
                <AiFillEyeInvisible className="w-5 relative bottom-1" />
              )}
            </IconButton>
          </div>
          <p className="m-0 text-sm text-bgError">
            {errors?.new_password?.message}
          </p>
        </div>

        <div className="relative">
          <label className="labelText" htmlFor={"email"}>
            Confirm Password
          </label>
          <div className={style.pswWrapper}>
            <input
              id={"password"}
              type={conpswType ? "password" : "text"}
              required={true}
              className="fixedInputClass"
              placeholder={"Confirm Your New Password"}
              {...register(`confirm_password`, {
                onChange: (e) => {
                  setValue(`confirm_password`, e.target.value.trimStart());
                },
              })}
            />
            <IconButton
              className={style.pswShow}
              onClick={() => handleView("confirmPassword")}
            >
              {!hideConfirmPassword ? (
                <AiFillEye className="w-5 relative bottom-1" />
              ) : (
                <AiFillEyeInvisible className="w-5 relative bottom-1" />
              )}
            </IconButton>
          </div>
          <p className="m-0 text-sm text-bgError">
            {errors?.confirm_password?.message}
          </p>
        </div>
      </div>
    </Popup>
  );
}
