import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";

import CustomBreadCrumbs from "../../../components/breadcrumbs/index";
import Tabs from "../../../customComponents/Tabs/Tabs";
import { useDataFieldStore } from "../../../store/datafields";
import { getAddonDetails } from "../apis/api.actions";
import moment from "moment";
import { useParams } from "react-router-dom";

export default function AddonDetailedView(props: any) {
  const { datafieldsItem } = useDataFieldStore();
  const params = useParams();

  const [tab, setTab] = useState("addonDetails");
  const [data, setData] = useState<any>([]);

  const [addonId, setAddonId] = useState(params.id);
  const tabs = [
    { label: "Detailed Information", value: "addonDetails", tagText: "Detailed Information" },
  ];

  const getData = (id: any) => {
    getAddonDetails(id).then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    getData(addonId);
  }, []);
  return (
    <div>
      <CustomBreadCrumbs
        data={[
          { title: "Addons", link: "/addon-list" },
          { title: datafieldsItem.name },
        ]}
      />

      <div className="customCardContainer bg-bgWhite !shadow-none !rounded-lg flex flex-col flex-1">
        <Tabs
          ariaLabel="consultation tabs"
          setTab={setTab}
          tab={tab}
          tabs={tabs}
        />

        {tab == "addonDetails" && (
          <div className="content tabContent">
            <div className="flex flex-col">
              <div className="grid gap-4 p-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Title</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data?.addon_type?.title ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Cost</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data?.currency ?? ""} {data?.price ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Total Sold</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data?.total_sold ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Total Revenue</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data?.total_revenue ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText"> Created At </label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data?.created_at ? moment(data?.created_at).format("DD-MM-YYYY") : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText"> Updated At </label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                    {data?.updated_at ? moment(data?.updated_at).format("DD-MM-YYYY") : "--"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
