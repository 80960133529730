import { Suspense } from "react";

import MainRoutes from "./routes/mainRoutes";

import "./App.css";
import "./styles/styles.scss";

function App() {
  
  return (
    <div className="App">
      <Suspense fallback={<h3> Loading</h3>}>
        <MainRoutes />
      </Suspense>
    </div>
  );
}

export default App;
