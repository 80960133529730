import { TableFooter } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { BsThreeDotsVertical } from "react-icons/bs";

import ActionMenu from "./components/actionMenu";
import { FilterDataType } from "./components/commontypes";
import NoDataFound from "./components/nodatafound";
import CustomTableCell from "./components/tableCell";
import CustomTableHead from "./components/tableheader";
import CustomTableToolbar from "./components/tabletoolbar";

import "./tablestyle.scss";

interface Data {
  calories: number;
  carbs: number;
  fat: number;
  name: string;
  protein: number;
}

type Props = {
  columns: {
    title: string;
    field: string;
    sort?: boolean;
    width?: string | number;
    maxWidth?: string | number;
    minWidth?: string | number;
    filter?: string;
    objectKey?: string;
    textStyle?: boolean;
    align?: any;
    render?: (data: any, index: number) => any;
  }[];
  selection?: boolean;
  title?: string;
  data: any;
  search?: boolean;
  actionProps?: {
    actionTitle: string;
    action: (values: any) => void;
    actionIcon: any;
    show?: (values: any) => boolean;
  }[];
  customFilter?: boolean;
  filterData?: FilterDataType;
  filterSubmit?: (values: FilterDataType) => void;
  filterClear?: () => void;
  rowsPerPage?: number;
  page?: number;
  pagination?: boolean;
  handlePagination?: (page: number, rowsPerPage: any) => void;
  searchValue?: string;
  handleGlobalSearch?: (search?: string) => void;
  maxBodyHeight?: string;
  minBodyHeight?: string;
  addButton?: any;
  totalCount?: number;
  isLoading?: boolean;
  exportButton?: any;
  customButtons?: any[];
  dateFilter?: boolean;
  handleDateFilter?: (data: any) => void;
  dateFilterKeys?: any;
};

const pageOptions: number[] = [25, 50];
type Order = "asc" | "desc";
type FormType = {
  filterData?: FilterDataType;
};
export default function CustomTable(props: Props) {
  const {
    data,
    columns,
    actionProps,
    selection,
    title,
    search,
    pagination,
    handleDateFilter,
    handlePagination,
    rowsPerPage,
    filterData,
    filterSubmit,
    filterClear,
    customFilter,
    handleGlobalSearch,
    searchValue,
    minBodyHeight,
    maxBodyHeight,
    addButton,
    totalCount,
    page,
    isLoading,
    exportButton,
    customButtons,
    dateFilter,
    dateFilterKeys,
  } = props;
  const methods = useForm<FormType>({
    mode: "onChange",
    defaultValues: { filterData },
  });
  const { fields } = useFieldArray({
    control: methods.control,
    name: "filterData.primary",
  });
  const { fields: secondaryFields } = useFieldArray({
    control: methods.control,
    name: "filterData.secondary",
  });
  const filterProps = {
    filterData,
    filterSubmit,
    filterClear,
    customFilter,
    handleGlobalSearch,
    searchValue,
    secondaryFields,
    handleSubmit: methods.handleSubmit,
    fields,
    addButton,
    exportButton,
    customButtons,
    dateFilter,
    handleDateFilter,
    dateFilterKeys,
  };

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("calories");
  const [selected, setSelected] = React.useState<readonly string[]>([]);

  const [menu, setMenu] = React.useState<null | HTMLElement>(null);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  React.useEffect(() => {
    methods.reset({ filterData: props.filterData });
  }, [props.filterData]);

  const handleChangePage = (event: unknown, newPage: number) => {
    handlePagination && handlePagination(newPage, rowsPerPage);
  };

  const handleRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    handlePagination && handlePagination(1, event?.target?.value);
  };
  const handleSelectAllClick = () => {};
  const handleSort = () => {};
  const [selectedRow, setSelectedRow] = React.useState<any>();
  const handleActionMennu = (e: any, row: any) => {
    setSelectedRow(row);
    setMenu(e?.currentTarget);
  };
  return (
    <div>
      <ActionMenu
        open={menu}
        data={actionProps?.filter(
          (actionProp) =>
            !actionProp.show || actionProp.show(selectedRow) === true
        )}
        row={selectedRow}
        onClose={() => setMenu(null)}
      />
      <Paper className="customCardContainer !shadow-none !rounded-lg">
        <FormProvider {...methods}>
          <CustomTableToolbar title={title} search={search} {...filterProps} />
          <TableContainer
            style={{ minHeight: minBodyHeight, maxHeight: maxBodyHeight }}
            className="pl-3 my-3 "
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              className=" border-b border-formBorder rounded-md"
            >
              <CustomTableHead
                numSelected={selected.length}
                order={order}
                columns={columns}
                actionProps={actionProps}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                handleSort={handleSort}
                rowCount={data.length}
                selection={selection}
              />
              {data.length > 0 && (
                <TableBody>
                  <>
                    {data?.map((row: any, rowIndex: number) => (
                      <TableRow key={row.id}>
                        {selection && (
                          <TableCell padding="checkbox">
                            <Checkbox color="primary" checked={row?.checked} />
                          </TableCell>
                        )}
                        {columns.map((col, colIndex) => (
                          <>
                            {col.render ? (
                              <TableCell
                                key={col.title}
                                style={{
                                  maxWidth: col.maxWidth ?? 250,
                                  minWidth: col.minWidth ?? "initial",
                                }}
                                align={col.align ?? "left"}
                                scope="row"
                                className={`tableCell ${
                                  col.textStyle !== false
                                    ? "hover:underline text-[#1650e2]"
                                    : ""
                                } h-1 truncate border border-formBorder border-r-0 border-b-0 !px-2.5 !py-2 !text-sm`}
                              >
                                {col.render(row, rowIndex)}
                              </TableCell>
                            ) : (
                              <CustomTableCell
                                key={col.title}
                                row={row}
                                col={col}
                              />
                            )}
                          </>
                        ))}
                        {actionProps && (
                          <TableCell
                            className="sticky right-0 w-1  bg-bgWhite border border-formBorder !text-sm   border-b-0 !px-2.5 !py-2	  h-1 Z-50"
                            align="center"
                          >
                            {actionProps.filter(
                              (actionProp) =>
                                !actionProp.show ||
                                actionProp.show(row) === true
                            ).length > 0 ? (
                              <IconButton
                                className="p-0"
                                disableRipple
                                onClick={(e: React.MouseEvent<HTMLElement>) =>
                                  handleActionMennu(e, row)
                                }
                              >
                                <BsThreeDotsVertical className="inline text-sm" />
                              </IconButton>
                            ) : (
                              <p></p>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </>
                </TableBody>
              )}
            </Table>
            {data.length === 0 && !isLoading && <NoDataFound />}
          </TableContainer>
          {pagination && (
            <TableFooter className="flex justify-between p-3 pt-0">
              <div className="flex">
                <select
                  className="border rounded-md border-formBorder !text-sm"
                  disabled={data.length == 0 ? true : false}
                  value={rowsPerPage}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    handleRowsPerPage(e)
                  }
                >
                  {pageOptions?.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <Pagination
                size="small"
                count={
                  totalCount && rowsPerPage
                    ? Math.ceil(totalCount / rowsPerPage)
                    : 0
                }
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
              />
            </TableFooter>
          )}
        </FormProvider>
      </Paper>
    </div>
  );
}
