import { getData, postData } from "../../../apis/api.helpers";
import apiUrl from "./api.url";

export const getProjectsReport = (perPage:number,page:number,search?:string ) =>
  getData(`${apiUrl.PROJECT_REPORT_LIST}?per_page=${perPage}&page=${page}&search=${search}`).then((res) => res.data);

export const getProjectDetails = (id:string ) =>
  getData(`${apiUrl.PROJECT_REPORT_DETAILS}/${id}`).then((res) => res.data);

export const getUsersReport = (perPage:number,page:number,search?:string ) =>
  getData(`${apiUrl.USER_REPORT_LIST}?per_page=${perPage}&page=${page}&search=${search}`).then((res) => res.data);

export const getUserReportDetails = (id:string ) =>
  getData(`${apiUrl.USER_REPORT_DETAILS}/${id}`).then((res) => res.data);

export const changeResolutionStatus = (payload: any) => 
  postData(apiUrl.CHANGE_RESOLUTION_STATUS, payload);

