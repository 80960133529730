import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";

import "./fieldstyle.scss";

type Props = {
  label: string;
  value: string | undefined;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  fullWidth?: boolean;
  required?: boolean;
  search?: boolean;
  placeholder?: string;
  handleClear: () => void;
};
export default function SearchField(props: Props) {
  const { label, value, onChange, handleClear, search, fullWidth } = props;
  return (
    <div>
      {label && (
        <label className="block justify-start text-xs mb-1">Text Field</label>
      )}
      <TextField
        {...props}
        id="outlined-disabled"
        fullWidth={fullWidth}
        value={value}
        label=""
        onChange={onChange}
        className="customSearchField"
        InputProps={{
          endAdornment: (
            <>
              {search && (
                <>
                  <IconButton disableRipple className="p-1">
                    <SearchOutlinedIcon />
                  </IconButton>
                  {value &&
                    <IconButton
                      disableRipple
                      className="p-1"
                      onClick={() => handleClear()}
                    >
                      <AiOutlineClose />
                    </IconButton>
                  }
                </>
              )}
            </>
          ),
        }}
      />
    </div>
  );
}
