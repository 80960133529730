import { Navigate, Outlet } from "react-router-dom";
import { useAuthStore } from '../../store/authStore';
import Layout from '../../layout/userLayout';


type Props = {
    children: React.ReactNode;
};

const UserRoute = ({ children }: Props) => {
    const authenticated = useAuthStore((state) => state.authenticated);

    return authenticated ? (
        <Layout>{children}</Layout> || <Outlet />
    ) : (
        <Navigate to="/login" replace />
    );
};

export default UserRoute;
