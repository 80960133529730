import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";

import CommonIcons from "./commonIcons";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
type Props = {
  open: HTMLElement | null;
  data?: {
    actionTitle: string;
    action: (values: any) => void;
    actionIcon: any;
    show?: (values: any) => boolean;
  }[];
  row?: any;

  onClose: () => void;
};
const ActionMenu = (props: Props) => {
  const { open, data, onClose, row } = props;
  const handleAction = (item: any) => {
    onClose();
    item.action(row);
  };
  return (
    <div>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={open}
        open={Boolean(open)}
        onClose={onClose}
      >
        {data
          ?.filter(
            (item) =>
              (item.show && item.show(row) === true) || item.show === undefined
          )
          .map((item) => (
            <MenuItem
              key={item.actionTitle}
              className="text-sm flex p-2"
              onClick={() => handleAction(item)}
              disableRipple
            >
              <CommonIcons iconName={item.actionIcon} className="mr-2" />
              {item.actionTitle}
            </MenuItem>
          ))}
      </StyledMenu>
    </div>
  );
};
export default ActionMenu;
