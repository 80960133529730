import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useAuthStore = create<AuthStoreType>()(
    persist(
        (set) => ({
            authenticated: undefined,
            setAuthenticated: (is_authenticated) => set(() => ({ authenticated: is_authenticated })),
            clearAuthenticated: () => set(() => ({ authenticated: undefined })),
        }),
        { name: "authenticated" }
    )
);

type AuthStoreType = {
    authenticated: boolean | undefined;
    setAuthenticated: (authenticated: boolean | undefined) => void;
    clearAuthenticated: () => void;
};
