const DISPUTE_LIST = "admin/list-disputes?";
const DISPUTE_DETAILS = "/admin/disputes";
const CHANGE_DISPUTE_STATUS = "dispute/change-status";

const apiUrl = {
  DISPUTE_LIST,
  DISPUTE_DETAILS,
  CHANGE_DISPUTE_STATUS,
};

export default apiUrl;
