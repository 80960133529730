import { MenuList } from "@mui/material";
import MuiMenu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

type Props = {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClick: () => void;
  handleClose: () => void;
  menuItems: any;
};
export default function Menu(props: Props) {
  const { anchorEl, open, handleClick, handleClose, menuItems } = props;
  const handleMenuClick = (action: () => void) => {
    handleClose();
    setTimeout(() => {
      action();
    }, 500);
  };
  return (
    <>
      <MuiMenu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClick={handleClick}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: 0,
              mr: 0,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 8,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList className="w-36 p-0">
          {menuItems.map((items: any, index: number) => (
            <MenuItem
              key={index}
              className="text-sm py-2"
              onClick={() => {
                handleMenuClick(items.action());
              }}
            >
              {items.name}
            </MenuItem>
          ))}
        </MenuList>
      </MuiMenu>
    </>
  );
}
