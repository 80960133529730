import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import * as React from "react";
import { AiOutlineClose } from "react-icons/ai";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
type Props = {
  open: boolean;
  title: string;
  className?: string;
  handleClose: () => void;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  children: React.ReactNode;
  primaryButton?: {
    title?: string;
    action: () => void;
    disabled?: boolean;
  };
  secondaryButton?: {
    title?: string;
    action: () => void;
    disabled?: boolean;
  };
};
export default function CustomDialogBox(props: Props) {
  const {
    open,
    handleClose,
    title,
    children,
    secondaryButton,
    maxWidth,
    primaryButton,
    className,
  } = props;
  return (
    <div className="">
      <Dialog
        open={open}
        maxWidth={maxWidth ?? "sm"}
        className={`min-w-fit ${className}`}
        onClose={handleClose}
      >
        <DialogTitle className="flex items-center text-base p-3 leading-none border-b border-cardBorder">
          <span className="grow text-base font-semibold">{title}</span>
          <IconButton
            className=" items-center p-0"
            disableRipple
            onClick={() => handleClose()}
          >
            <AiOutlineClose className="w-4 text-primary" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="p-3">{children}</DialogContent>
        <DialogActions className="p-3 flex gap-2 border-t border-cardBorder">
          {secondaryButton && (
            <Button
              className="secondaryButton h-8 text-sm capitalize min-w-[72px] !rounded-md"
              onClick={secondaryButton.action}
              disabled={secondaryButton.disabled}
            >
              {secondaryButton.title}
            </Button>
          )}
          {primaryButton && (
            <Button
              className="primaryButton h-8 text-sm capitalize min-w-[72px] !rounded-md"
              onClick={primaryButton.action}
              disabled={primaryButton.disabled}
            >
              {primaryButton.title}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
