import { ReactComponent as Logo } from "./upcoming-feature.svg"

export default function UpcomingFeature()
{
    return (
        <div className="content tabContent ">
              <div className="flex flex-col p-3 items-center">
                <Logo />
                <p className="font-bold">Upcoming Feature in Future Phase</p>
                <p className="text-secondary">Stay Tuned: Exciting things are on the horizon with our upcoming feature.</p>
              </div>
            </div>
    )
}