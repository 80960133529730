import { deleteData, getData, postData, updateData } from "../../../apis/api.helpers";
import apiUrl from "./api.url";

export const getReviewList = (perPage:number,page:number,search?:string ) =>
  getData(`${apiUrl.REVIEW_LIST}?per_page=${perPage}&page=${page}&search=${search}`).then((res) => res.data);

  export const getReviewDetails = (id:string ) =>
  getData(`${apiUrl.REVIEW_DETAILS}/${id}`).then((res) => res.data);

  export const deleteReview = (id: string) =>
          deleteData(`${apiUrl.DELETE_REVIEW}/${id}`).then((res) => res);
