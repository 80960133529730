import { lazy } from "react";
import { Route } from "react-router-dom";

import Addons from "../../pages/addon/addon";
import AddonDetailedView from "../../pages/addon/components/detailView";
import DisputeDetailedView from "../../pages/disputes/detailedView";
import Disputes from "../../pages/disputes/disputes";
import MilestoneDetailedView from "../../pages/milestones/detailedView";
import PaymentDetailedView from "../../pages/payments/detailedView";
import Payments from "../../pages/payments/payments";
import PayoutDetailedView from "../../pages/payoutRequests/detailedView";
import PayoutRequests from "../../pages/payoutRequests/payoutRequests";
import ProjectReviewDetailedView from "../../pages/projectReviews/components/detailView";
import ProjectReviews from "../../pages/projectReviews/projectReviews";
import ProjectReviewsSuperAdmin from "../../pages/projectReviews/superAdminProjectReviews";
import PurchaseDetailedView from "../../pages/purchases/detailedView";
import Purchases from "../../pages/purchases/purchases";
import ProjectReprotDetails from "../../pages/reporting/components/projectreportdetails";
import UserReprotDetails from "../../pages/reporting/components/userreportdetails";
import Reportings from "../../pages/reporting/reporting";
import WorkTransactionDetailedView from "../../pages/workTransactions/detailedView";
import WorkTransactions from "../../pages/workTransactions/workTransactions";
import UserRoute from "./userRoute";

const User = lazy(() => import("../../pages/user/user"));
const Company = lazy(() => import("../../pages/company/components/index"));
const Jobs = lazy(() => import("../../pages/jobs/jobs"));

const DetailView = lazy(() => import("../../pages/user/components/detailView"));
const CompanyDetailView = lazy(
  () => import("../../pages/company/components/detaiview")
);
const JobDetailView = lazy(
  () => import("../../pages/jobs/components/detailView")
);
const Gig = lazy(() => import("../../pages/gig/gig"));
const DeletedGig = lazy(() => import("../../pages/gig/deletedGig"));

const GigDetailView = lazy(
  () => import("../../pages/gig/components/detailView")
);

const Plan = lazy(() => import("../../pages/plan/plan"));
const PlanDetailView = lazy(
  () => import("../../pages/plan/components/detailView")
);
const LanguageSwitcher = lazy(
  () => import("../../pages/languageSwitcher/index")
);

const DataFields = [
  <Route
    element={
      <UserRoute>
        <User />
      </UserRoute>
    }
    path="/user-list"
    key="/user-list"
  />,
  <Route
    element={
      <UserRoute>
        <Company />
      </UserRoute>
    }
    path="/Company-list"
    key="/Company-list"
  />,
  <Route
    element={
      <UserRoute>
        <LanguageSwitcher />
      </UserRoute>
    }
    path="/language-switcher"
    key="/language-switcher"
  />,
  <Route
    element={
      <UserRoute>
        <ProjectReprotDetails />
      </UserRoute>
    }
    path="/reporting-project/:id"
    key="/reporting-project/:id"
  />,
  <Route
    element={
      <UserRoute>
        <UserReprotDetails />
      </UserRoute>
    }
    path="/reporting-user/:id"
    key="/reporting-user/:id"
  />,

  <Route
    element={
      <UserRoute>
        <DetailView />
      </UserRoute>
    }
    path="/user/:id"
    key="/user/:id"
  />,
  <Route
    element={
      <UserRoute>
        <CompanyDetailView />
      </UserRoute>
    }
    path="/company/:id"
    key="/company/:id"
  />,
  // <Route
  //   element={
  //     <UserRoute>
  //       <Jobs />
  //     </UserRoute>
  //   }
  //   path="/job-list"
  //   key="/job-list"
  // />,

  // <Route
  //   element={
  //     <UserRoute>
  //       <JobDetailView />
  //     </UserRoute>
  //   }
  //   path="/job/:id"
  //   key="/job/:id"
  // />,
  <Route
    element={
      <UserRoute>
        <Gig />
      </UserRoute>
    }
    path="/project-list"
    key="/project-list"
  />,
  <Route
  element={
    <UserRoute>
      <DeletedGig />
    </UserRoute>
  }
  path="/deleted-project-list"
  key="/deleted-project-list"
/>,
  <Route
    element={
      <UserRoute>
        <MilestoneDetailedView />
      </UserRoute>
    }
    path="/milestone/:id"
    key="/milestone/:id"
  />,

  <Route
    element={
      <UserRoute>
        <GigDetailView />
      </UserRoute>
    }
    path="/project/:id"
    key="/project/:id"
  />,

  <Route
    element={
      <UserRoute>
        <Plan />
      </UserRoute>
    }
    path="/plan-list"
    key="/plan-list"
  />,

  <Route
    element={
      <UserRoute>
        <PlanDetailView />
      </UserRoute>
    }
    path="/plan/:id"
    key="/plan/:id"
  />,

  <Route
    element={
      <UserRoute>
        <Addons />
      </UserRoute>
    }
    path="/addon-list"
    key="/addon-list"
  />,

  <Route
    element={
      <UserRoute>
        <AddonDetailedView />
      </UserRoute>
    }
    path="/addon/:id"
    key="/addon/:id"
  />,

  // <Route
  //   element={
  //     <UserRoute>
  //       <Payments />
  //     </UserRoute>
  //   }
  //   path="/payment-list"
  //   key="/payment-list"
  // />,

  // <Route
  //   element={
  //     <UserRoute>
  //       <PaymentDetailedView />
  //     </UserRoute>
  //   }
  //   path="/payment/:id"
  //   key="/payment/:id"
  // />,

  <Route
    element={
      <UserRoute>
        <PayoutRequests />
      </UserRoute>
    }
    path="/payout-list"
    key="/payout-list"
  />,

  <Route
    element={
      <UserRoute>
        <PayoutDetailedView />
      </UserRoute>
    }
    path="/payout/:id"
    key="/payout/:id"
  />,

  <Route
    element={
      <UserRoute>
        <Purchases />
      </UserRoute>
    }
    path="/purchase-list"
    key="/purchase-list"
  />,

  <Route
    element={
      <UserRoute>
        <PurchaseDetailedView />
      </UserRoute>
    }
    path="/purchase/:id"
    key="/purchase/:id"
  />,

  <Route
    element={
      <UserRoute>
        <WorkTransactions />
      </UserRoute>
    }
    path="/work-transaction-list"
    key="/work-transaction-list"
  />,

  <Route
    element={
      <UserRoute>
        <WorkTransactionDetailedView />
      </UserRoute>
    }
    path="/work-transaction/:id"
    key="/work-transaction/:id"
  />,
  <Route
    element={
      <UserRoute>
        <Disputes />
      </UserRoute>
    }
    path="/dispute-list"
    key="dispute-list"
  />,
  <Route
    element={
      <UserRoute>
        <DisputeDetailedView />
      </UserRoute>
    }
    path="/dispute/:id"
    key="/dispute/:id"
  />,
  <Route
    element={
      <UserRoute>
        <ProjectReviews />
      </UserRoute>
    }
    path="/review-list"
    key="review-list"
  />,
  <Route
    element={
      <UserRoute>
        <ProjectReviewsSuperAdmin />
      </UserRoute>
    }
    path="/manage-reviews-list-admin"
    key="manage-reviews-list-admin"
  />,
  <Route
    element={
      <UserRoute>
        <ProjectReviewDetailedView />
      </UserRoute>
    }
    path="/review/:id"
    key="/review/:id"
  />,
  <Route
    element={
      <UserRoute>
        <Reportings />
      </UserRoute>
    }
    path="/reporting-list"
    key="reporting-list"
  />,
];

export default DataFields;
