import {
  AiOutlineDownload,
  AiOutlineLogin,
  AiOutlineMail,
} from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import { FaExchangeAlt, FaHandshake } from "react-icons/fa";
import { GrView } from "react-icons/gr";
import { MdDeleteOutline } from "react-icons/md";
import { CgBlock, CgUnblock } from "react-icons/cg";

type Props = { iconName?: string; className?: string };
export default function CommonIcons(props: Props) {
  const { iconName, className } = props;
  switch (iconName) {
    case "edit":
      return <CiEdit className={className} />;
    case "delete":
      return <MdDeleteOutline className={className} />;
    case "change":
      return <FaExchangeAlt className={className} />;
    case "view":
      return <GrView className={className} />;
    case "mail":
      return <AiOutlineMail className={className} />;
    case "block":
      return <CgBlock className={className} />;
    case "unblock":
      return <CgUnblock className={className} />;
    case "download":
      return <AiOutlineDownload className={className} />;
    case "login":
      return <AiOutlineLogin className={className} />;
    case "mark_as_settled":
      return <FaHandshake className={className} />;
    default:
      return null;
  }
}
