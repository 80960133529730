import { zodResolver } from "@hookform/resolvers/zod";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import UpcomingFeature from "../../components/upcomingFeature";
import CustomDialogBox from "../../customComponents/dialogbox";
import SelectField from "../../customComponents/selectfield";
import CustomTable from "../../customComponents/table/table";
import Tabs from "../../customComponents/Tabs/Tabs";
import TextArea from "../../customComponents/textfield/textarea";
import { useDataFieldStore } from "../../store/datafields";
import { useLoaderStore } from "../../store/loaderStore";
import {
  changeResolutionStatus,
  getProjectsReport,
  getUsersReport,
} from "./apis/api.action";
import { reportingSchema } from "./schema";

export default function Reportings(props: any) {
  const [rowsPerPage, setRowsPerPage] = useState<any>(25);
  const [newPage, setNewPage] = useState<any>(1);
  const { setLoader, loader } = useLoaderStore();
  const { enqueueSnackbar } = useSnackbar();
  const [searchValue, setSearchValue] = useState<string | undefined>("");
  const [data, setData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<any>();
  const { setDataFieldsitem } = useDataFieldStore();
  const navigate = useNavigate();
  const [showChangeStatustPopup, setShowChangeStatustPopup] = useState(false);
  const [entity, setEntity] = useState("");

  const [tab, setTab] = useState("userReport");

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setError,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      resolution_status: "",
      comment: "",
    },
    resolver: zodResolver(reportingSchema),
    mode: "onChange",
  });

  const tabs = [
    {
      label: "User Reports",
      value: "userReport",
      tagText: "Detailed Information",
    },
    {
      label: "Project Reports",
      value: "projectReport",
      tagText: "Detailed Information",
    },
  ];

  const getUserFields = (
    rows_per_page: number,
    new_page: number,
    search?: string
  ) => {
    setLoader(true);
    getUsersReport(rows_per_page, new_page, search ?? searchValue)
      .then((res) => {
        setTotalCount(res.total);
        setData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  };
  const getProjectFields = (
    rows_per_page: number,
    new_page: number,
    search?: string
  ) => {
    setLoader(true);
    getProjectsReport(rows_per_page, new_page, search ?? searchValue)
      .then((res) => {
        setTotalCount(res.total);
        setData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  };
  useEffect(() => {
    if (tab == "userReport") {
      setEntity("user");
      getUserFields(rowsPerPage, newPage);
    } else {
      setEntity("project");
      getProjectFields(rowsPerPage, newPage);
    }
  }, [tab]);

  const userColumns = [
    {
      title: "Report ID",
      field: "display_id",
      type: "string",
      minWidth: 200,
      render: (row: any) => (
        <Link to={``} onClick={(e) => handleUserLink(e, row)}>
          {row.display_id}
        </Link>
      ),
    },
    {
      title: "User ID",
      field: "display_id",
      type: "string",
      render: (row: any) => (
        <Link to={``} onClick={(e) => handleUserIdLink(e, row)}>
          {row.user?.display_id}
        </Link>
      ),
    },
    {
      title: "Reason",
      field: "complaint.name",
      type: "string",
      textStyle: false,
      render: (row: any) => (
        <div className="flex">
          <span>{row.complaint?.name}</span>
        </div>
      ),
    },
    {
      title: "Remarks",
      field: "description",
      type: "string",
    },
    {
      title: "Reported On",
      field: "created_at",
      type: "date",
      minWidth: 150,
    },
    {
      title: "Reported By",
      field: "created_by.name",
      type: "string",
      textStyle: false,
      render: (row: any) => (
        <div className="flex">
          <span>{row.created_by?.name}</span>
        </div>
      ),
    },
    {
      title: "Resolution Status",
      field: "resolution_status",
      type: "string",
    },
    {
      title: "Action Taken Date",
      field: "resolved_at",
      type: "date",
    },
  ];
  const handleProjectLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    setDataFieldsitem({ name: row.name, key: row.id });
    navigate(`/reporting-project/${row.id}`);
  };
  const handleUserLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    setDataFieldsitem({ name: row.name, key: row.id });
    navigate(`/reporting-user/${row.id}`);
  };
  const handleUserIdLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    navigate(`/user/${row.user?.id}`);
  };
  const handleProjectIdLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    navigate(`/project/${row?.work?.id}`);
  };
  const projectColumns = [
    {
      title: "Report ID",
      field: "display_id",
      type: "string",
      minWidth: 200,
      render: (row: any) => (
        <Link to={``} onClick={(e) => handleProjectLink(e, row)}>
          {row.display_id}
        </Link>
      ),
    },
    {
      title: "Project ID",
      field: "display_id",
      type: "string",
      render: (row: any) => (
        <Link to={``} onClick={(e) => handleProjectIdLink(e, row)}>
          {row?.work?.display_id}
        </Link>
      ),
    },
    {
      title: "Reason",
      field: "complaint.name",
      type: "string",
      textStyle: false,
      render: (row: any) => (
        <div className="flex">
          <span>{row.complaint?.name}</span>
        </div>
      ),
    },
    {
      title: "Remarks",
      field: "description",
      type: "string",
    },
    {
      title: "Reported On",
      field: "created_at",
      type: "date",
      minWidth: 150,
    },
    {
      title: "Reported By",
      field: "created_by.name",
      type: "string",
      textStyle: false,
      render: (row: any) => (
        <div className="flex">
          <span>{row.created_by?.name}</span>
        </div>
      ),
    },
    {
      title: "Resolution Status",
      field: "resolution_status",
      type: "string",
    },
    {
      title: "Action Taken Date",
      field: "resolved_at",
      type: "string",
    },
  ];
  const handleChangeStatus = (data: any) => {
    setSelectedRow(data);
    if (data.resolution_status === "Pending") {
      setStatusList([
        { id: "Hide the entity", label: "Hide the entity" },
        { id: "Cancel report", label: "Cancel report" },
      ]);
    } else {
      setStatusList([{ id: "Pending", label: "Pending" }]);
    }
    setShowChangeStatustPopup(true);
  };
  let actionProps = [
    {
      actionTitle: "View",
      action: (data: any) =>
        tab == "userReport"
          ? navigate(`/reporting-user/${data.id} `)
          : navigate(`/reporting-project/${data.id} `),
      actionIcon: "view",
    },
    {
      actionTitle: "Change Status",
      action: handleChangeStatus,
      actionIcon: "edit",
      show: (row: any) =>
        !(
          row?.resolution_status === "Hide the entity" ||
          row?.resolution_status === "Cancel report"
        ),
    },
  ];
  const [selectedRow, setSelectedRow] = useState<any>();
  const [statusList, setStatusList] = useState([
    { id: "Pending", label: "Pending" },
  ]);

  const handleChangeStatusConfirmed = () => {
    handleSubmit(handleValidatedData)();
  };
  const handleValidatedData = (formData: any) => {
    let payload: any = {
      report_id: selectedRow.id,
      resolution_status: formData?.resolution_status,
      comment: formData?.comment,
      entity: entity,
    };
    if (tab == "userReport") {
      changeResolutionStatus(payload)
        .then((res) => {
          getUserFields(rowsPerPage, newPage);
          enqueueSnackbar(res.message, {
            variant: res.status === true ? "success" : "error",
          });
          handleChangeStatusPopupClose();
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: "error",
          });
        });
    } else {
      changeResolutionStatus(payload)
        .then((res) => {
          getProjectFields(rowsPerPage, newPage);
          enqueueSnackbar(res.message, {
            variant: res.status === true ? "success" : "error",
          });
          handleChangeStatusPopupClose();
        })
        .catch((err) => {
          enqueueSnackbar(err?.response?.data?.message, {
            variant: "error",
          });
        });
    }
  };
  const handleRefresh = () => {
    getUserFields(rowsPerPage, newPage);
  };
  const handlePagination = (new_page: number, rows_per_page: number) => {
    setNewPage(new_page);
    setRowsPerPage(rows_per_page);
    // getDataFields(rows_per_page, new_page);
    if (tab == "userReport") {
      getUserFields(rowsPerPage, newPage);
    } else {
      getProjectFields(rowsPerPage, newPage);
    }
  };
  const handleGlobalSearch = (search?: string) => {
    setSearchValue(search);
    // getDataFields(rowsPerPage, newPage, search);
    if (tab == "userReport") {
      getUserFields(rowsPerPage, newPage, search);
    } else {
      getProjectFields(rowsPerPage, newPage, search);
    }
  };
  const currentTab = tabs.find((tabData) => tabData.value === tab);
  const columnSelect = () => {
    switch (tab) {
      case "userReport":
        return userColumns;
      case "projectReport":
        return projectColumns;
      default:
        return [];
    }
  };
  const handleChangeStatusPopupClose = () => {
    setShowChangeStatustPopup(false);
    setSelectedRow(undefined);
    setStatusList([{ id: "Processing", label: "Processing" }]);
    reset();
  };

  return (
    <div>
      {/* <UpcomingFeature /> */}
      <div className="">
        <Tabs
          ariaLabel="consultation tabs"
          setTab={setTab}
          tab={tab}
          tabs={tabs}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTable
              data={data}
              columns={columnSelect()}
              // title={"Reports"}
              maxBodyHeight="calc(100Vh - 237px)"
              minBodyHeight="calc(100Vh - 237px)"
              search={true}
              pagination={true}
              customFilter={false}
              handlePagination={handlePagination}
              totalCount={totalCount}
              rowsPerPage={rowsPerPage}
              isLoading={loader}
              page={newPage}
              searchValue={searchValue}
              handleGlobalSearch={handleGlobalSearch}
              actionProps={actionProps}
            />
          </Grid>
        </Grid>
        <CustomDialogBox
          open={showChangeStatustPopup}
          title="Change Resolution Status"
          handleClose={handleChangeStatusPopupClose}
          primaryButton={{
            title: "Update",
            action: handleChangeStatusConfirmed,
          }}
          secondaryButton={{
            title: "Cancel",
            action: handleChangeStatusPopupClose,
          }}
          maxWidth="xl"
        >
          <div className="grid grid-cols-1 gap-4 p-2">
            <div className="col-start-1 col-span-1">
              <Controller
                name="comment"
                control={control}
                render={({ field }) => {
                  return (
                    <TextArea
                      label="Comment"
                      required={true}
                      error={errors?.comment?.message}
                      {...field}
                    />
                  );
                }}
              />
            </div>
            <div className="col-start-1 col-span-1">
              <Controller
                name="resolution_status"
                control={control}
                render={({ field }) => {
                  return (
                    <SelectField
                      label="Resolution Status"
                      required={true}
                      data={statusList}
                      error={errors?.resolution_status?.message}
                      {...field}
                      placeholder="Select"
                    />
                  );
                }}
              />
            </div>
          </div>
        </CustomDialogBox>
      </div>
    </div>
  );
}
