import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";

import CustomBreadCrumbs from "../../components/breadcrumbs/index";
import Tabs from "../../customComponents/Tabs/Tabs";
import { useDataFieldStore } from "../../store/datafields";
import Favourite from "../../components/favourite/index";
import moment from "moment";
import { getDisputeDetails } from "./apis/api.actions";
import { capitalize } from "../../utilities/format";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlineArrowDown } from "react-icons/ai";

export default function DisputeDetailedView(props: any) {
  const { datafieldsItem, setDataFieldsitem } = useDataFieldStore();
  const navigate = useNavigate();
  const params = useParams();
  const [tab, setTab] = useState("disputeDetails");
  const [data, setData] = useState<any>([]);
  const [favouriteData, setFavoriteData] = useState<any>([]);

  const [transactionId, setTransactionId] = useState(params.id);
  const tabs = [
    {
      label: "Detailed Information",
      value: "disputeDetails",
      tagText: "Detailed Information",
    },
  ];

  const getData = (id: any) => {
    getDisputeDetails(id).then((res) => {
      setData(res);
    });
  };

  const handleProjectLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    if (row.work?.id) {
      setDataFieldsitem({
        name: row.work?.title,
        key: row.work?.id,
      });

      navigate(`/project/${row.work?.id}`);
    }
  };

  useEffect(() => {
    getData(transactionId);
  }, []);

  return (
    <div>
      <CustomBreadCrumbs
        data={[
          { title: "Dispute Tickets", link: "/dispute-list" },
          { title: data.display_id ?? "--" },
        ]}
      />
      <div className=" bg-bgWhite customCardContainer !shadow-none !rounded-lg flex flex-col flex-1">
        <Tabs
          ariaLabel="consultation tabs"
          setTab={setTab}
          tab={tab}
          tabs={tabs}
        />
        {tab === "disputeDetails" && (
          <div className="content tabContent ">
            <div className="flex flex-col">
              <div className="p-1.5 px-3 flex items-center justify-start w-full bg-tableHeader">
                <p className="text-sm font-semibold">Dispute Details</p>
              </div>
              <div className="grid gap-4 p-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Dispute Ticket ID</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.display_id ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Dispute Type</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.dispute_type ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Project Title</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data?.work?.work_type?.slug === "gig" ? (<Link className="hover:underline text-[#1650e2]" to={``} onClick={(e) => handleProjectLink(e, data)}>
          {data.work?.title ?? "--"}
        </Link>) : (
          <span>{data.work?.title ?? "--"}</span>
        )}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Milestone ID</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.bid_milestone?.display_id ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Milestone Title</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.bid_milestone?.name ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Milestone Status</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.bid_milestone?.milestone_status ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Dispute Reason</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.dispute_reason?.name ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Dispute Description</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.description ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Raised Date</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.created_at
                        ? moment(data.created_at).format("DD-MM-YYYY")
                        : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Raised By</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.raised_by ? (
                        <span>{data.raised_by?.name} ({data.raised_by?.work_relation})</span>
                      ) : "--"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-1.5 px-3 flex items-center justify-start w-full bg-tableHeader">
                <p className="text-sm font-semibold">Attachment</p>
              </div>
              <div className="grid gap-4 p-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">File</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                    {data?.actualAttachmentPath?.length > 0 ? (
                        <a
                        className="flex "
                         href={data?.actualAttachmentPath}
                         download target="_blank" rel="noreferrer">
                          <span className="mr-1 text-xl"> <AiOutlineArrowDown/> </span> 
                           {data?.file_name ?? "--"}
                        </a>
                        
                      ) : (
                        "--"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-1.5 px-3 flex items-center justify-start w-full bg-tableHeader">
                <p className="text-sm font-semibold">Resolution Details</p>
              </div>
              <div className="grid gap-4 p-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Dispute Status</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.dispute_status ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Resolution Remarks from Dawam</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.resolved_comment ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Closed Date</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.resolved_at
                        ? moment(data.resolved_at).format("DD-MM-YYYY")
                        : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Last Updated</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.updated_at
                        ? moment(data.updated_at).format("DD-MM-YYYY")
                        : "--"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
