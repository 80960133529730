import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useDataFieldStore = create<AuthStoreType>()(
  persist(
    (set) => ({
      datafieldsItem: { key: "", name: "" },
      setDataFieldsitem: (items) => set(() => ({ datafieldsItem: items })),
    }),
    { name: "datFieldItem" }
  )
);
type Items = {
  key: string;
  name: string;
};

type AuthStoreType = {
  datafieldsItem: Items;
  setDataFieldsitem: (item: Items) => void;
};
export const useSysLanguageStore = create<LangType>()(
  persist(
    (set) => ({
      languageList: [{ id: "", label: "" }],
      setLanguageList: (items) => set(() => ({ languageList: items })),
    }),
    { name: "language_list" }
  )
);

type LangType = {
  languageList: {
    id: string;
    label?: string;
  }[];
  setLanguageList: (item: any) => void;
};
