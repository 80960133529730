import { getData, postData } from "../api.helpers";
import apiUrl from "../api.url";

export const login = (data: any) =>
  postData(apiUrl.LOGIN_URL, data).then((res) => res.data);

export const registerApi = (data: any) =>
  postData(apiUrl.REGISTER, data).then((res) => res);

export const verifyEmail = (data: any) =>
  postData(apiUrl.VERIFY_EMAIL, data).then((res) => res.data);
export const verifyUserName = (data: string) =>
  getData(`${apiUrl.DUPLICATE_USER}${data}`).then((res) => res.data);
export const forgotPassword = (data: any) =>
  postData(apiUrl.FORGOTPASSWORD, data).then((res) => res);

export const resetPassword = (id: any, data: any) =>
  postData(`${apiUrl.RESETPASSWORD}/${id}`, data).then((res) => res.data);

export const changePassword = (data: any) =>
  postData(apiUrl.CHANGE_PASSWORD, data).then((res) => res);

export const sysLangList = () =>
  getData(apiUrl.LANG_LIST).then((res) => res.data);

export const getProfile = () =>
  getData(apiUrl.GET_PROFILE).then((res) => res.data);

export const updateProfile = (data: any) =>
  postData(apiUrl.UPDATE_PROFILE, data).then((res) => res);

export const updateAdminProfile = (data: any) =>
  postData(apiUrl.UPDATE_ADMIN_PROFILE, data).then((res) => res);

export const getCountry = (searchKey?:string) =>
  getData(`${apiUrl.GET_COUNTRY}&search=${searchKey}`).then((res) => res.data);

export const getState = (data: any) =>
  getData(`${apiUrl.GET_STATE}/${data}/states`).then((res) => res.data);
