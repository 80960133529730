import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import React, { useEffect, useState } from "react";
import { BiUserCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import ChangePassword from "../components/changePassword";
import ChatComponent from "../components/chat/chat";
import Notification from "../components/notification/notification";
import config from "../config";
import Menu from "../customComponents/Menu";
import { useAuthStore } from "../store/authStore";
import { useLoaderStore } from "../store/loaderStore";

let key = 1;
export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    key = key + 1;
  };
  const handleLogout = () => {
    localStorage.clear();
    setAuthenticated(false);
  };

  const { setAuthenticated } = useAuthStore();
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const { loader } = useLoaderStore();

  const menuItems = [
    { name: "Profile", action: () => navigate("/profile") },
    { name: "Change Password", action: () => setOpenPopup(true) },
    { name: "Logout", action: () => handleLogout() },
  ];
  const [avatar, setAvatar] = useState(localStorage.getItem("avatar"));

  useEffect(() => {
    const handleStorage = () => {
      setAvatar(localStorage.getItem("avatar"));
    };

    window.addEventListener("storage", () => handleStorage());
    return () => window.removeEventListener("storage", () => handleStorage());
  });

  return (
    <>
      <ChangePassword open={openPopup} setOpen={setOpenPopup} />
      <header
        className={` flex justify-between items-center bg-slate-800 shadow-md p-3 bg-white`}
      >
        <div className="flex w-16">
          <a href={window.location.origin}>
            {" "}
            <img src={config.PROJECT_LOGO} alt="D" />
          </a>
        </div>
        <div className="flex items-center gap-4">
          {/* <ChatComponent />
          <Notification /> */}
          <IconButton
            disableRipple
            onClick={(e) => {
              handleClick(e);
            }}
            className="p-0 w-8 h-8 bg-mainBgColor "
          >
            {avatar !== "null" ? (
              <img
                className="rounded-lg w-8 h-8"
                src={localStorage.getItem("avatar") ?? ""}
                alt=""
              />
            ) : (
              <BiUserCircle className="p-0  text-primary text-xl" />
            )}
          </IconButton>
        </div>

        <Menu
          key={key}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          handleClick={handleClose}
          handleClose={handleClose}
          menuItems={menuItems}
        />
      </header>
      {loader && <LinearProgress />}
    </>
  );
}
