import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";

type Order = "asc" | "desc";
interface Data {
  calories: number;
  carbs: number;
  fat: number;
  name: string;
  protein: number;
}
interface EnhancedTableProps {
  numSelected: number;

  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  handleSort: (title: string) => void;
  selection?: boolean;
  actionProps?: {
    actionTitle?: string;
    action?: (values: any) => void;
    actionIcon?: any;
  }[];
  columns: {
    title: string;
    field: string;
    sort?: boolean;
    type?: string;
    width?: string | number;
    maxWidth?: string | number;
    filter?: string;
  }[];
}

const CustomTableHead = (props: EnhancedTableProps) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    handleSort,
    columns,
    selection,
    actionProps,
  } = props;

  return (
    <TableHead className="z-50	">
      <TableRow>
        {selection && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        )}
        {columns.map((headCell) => (
          <TableCell
            key={headCell.title}
            className="p-2.5  min-h-min text-[11px] font-bold border border-cardBorder border-r-0 border-b-0 bg-tableHeader"
            style={{ minWidth: 100, maxWidth: headCell.maxWidth ?? 100 }}
            align={headCell.type === "numeric" ? "right" : "left"}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell?.sort ? (
              <TableSortLabel
                active={orderBy === headCell.title}
                direction={orderBy === headCell.title ? order : "asc"}
                onClick={() => handleSort(headCell.title)}
              >
                {headCell.title}
                {orderBy === headCell.title ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <>{headCell.title}</>
            )}
          </TableCell>
        ))}
        {actionProps && (
          <TableCell className="sticky right-0 w-1 p-2.5 text-[11px] font-bold   border border-cardBorder border-b-0 bg-tableHeader">
            Actions
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
export default CustomTableHead;
