const LOGIN_URL = "admin/login";
const REGISTER = "register";
const FORGOTPASSWORD = "admin/forgot-password";
const RESETPASSWORD = "reset-password";
const VERIFY_EMAIL = "verify-account";
const CHANGE_PASSWORD = "change-password";
const LANG_LIST = "system-languages";
const GET_PROFILE = "get-userprofile";
const UPDATE_PROFILE = "update-userprofile";
const UPDATE_ADMIN_PROFILE = "admin/update-profile";
const GET_COUNTRY = "picklist/country?per_page=150";
const GET_STATE = "/admin/country";
const GET_GENDER = "picklist/gender?page=1&per_page=10";
const GET_EMPLOYEE_STATUS = "picklist/employment-status?page=1&per_page=10";
const GET_INDUSTRIES = "picklist/industry?page=1&per_page=100";
const GET_WORK_SECTORS = "picklist/work-sector?page=1&per_page=100";
const DUPLICATE_USER = "check-duplicate-user?user_name=";
const apiUrl = {
  LOGIN_URL,
  REGISTER,
  FORGOTPASSWORD,
  RESETPASSWORD,
  VERIFY_EMAIL,
  CHANGE_PASSWORD,
  LANG_LIST,
  GET_PROFILE,
  DUPLICATE_USER,
  UPDATE_PROFILE,
  UPDATE_ADMIN_PROFILE,
  GET_COUNTRY,
  GET_STATE,
  GET_GENDER,
  GET_EMPLOYEE_STATUS,
  GET_INDUSTRIES,
  GET_WORK_SECTORS,
};

export default apiUrl;
