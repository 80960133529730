import Header from "./header";
import SideNav from "./sidenav";

export default function Layout({ children }: any) {
  return (
    <div className="flex h-screen bg-green-300">
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <div className="flex h-full overflow-hidden">
          <SideNav />
          <main className="flex flex-col w-full h-full bg-mainBgColor overflow-x-hidden overflow-y-auto  p-4">
            {children}
          </main>
        </div>
      </div>
    </div>
  );
}
