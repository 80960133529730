import { getData } from "../../../apis/api.helpers";
import apiUrl from "./api.url";

export const getPurchaseList = (
  perPage: number,
  page: number,
  search?: string,
  filters?: string
) =>
  getData(
    `${apiUrl.PURCHASE_LIST}per_page=${perPage}&page=${page}&search=${search}${filters}`
  ).then((res) => res.data);

export const getPurchaseDetails = (id: string) =>
  getData(`${apiUrl.PURCHASE_DETAILS}/${id}`).then((res) => res.data);
