import TextField from "@mui/material/TextField";

import "./fieldstyle.scss";

type Props = {
  label?: string;
  value?: string | number;
  onChange?: (e: any) => void;
  fullWidth?: boolean;
  required?: boolean;
  search?: boolean;
  name?: string;
  type?: string;
  register?: any;
  placeholder?: string;
  disabled?: boolean;
  lang?: string;
  error?: string;
  className?: string;
  multiline?: boolean;
};
export default function CustomInput(props: Props) {
  const {
    label,

    name,
    register,
    required,
    error,
    fullWidth,
    lang,
    disabled,
    className,
  } = props;
  return (
    <div>
      {label && required ? (
        <label className=" justify-start labelText">
          {" "}
          {label} <span className="text-red">*</span>
        </label>
      ) : (
        <label className=" justify-start labelText"> {label}</label>
      )}

      <TextField
        {...props}
        id="outlined-disabled"
        className={`${className} customField text-sm`}
        fullWidth={fullWidth}
        name={name}
        error={error}
        helperText={error}
        dir={lang === "ar" ? "rtl" : ""}
        lang={lang ?? "en"}
        {...register}
        label=""
        inputProps={{ lang: lang ?? "en" }}
        disabled={disabled}
      />
    </div>
  );
}
