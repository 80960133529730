import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";

import CustomBreadCrumbs from "../../components/breadcrumbs/index";
import Tabs from "../../customComponents/Tabs/Tabs";
import { getMilestoneDetails } from "./apis/api.actions";
import moment from "moment";
import { useDataFieldStore } from "../../store/datafields";
import { useParams } from "react-router-dom";

export default function MilestoneDetailedView(props: any) {
  const { datafieldsItem } = useDataFieldStore();
  const params = useParams();

  const [tab, setTab] = useState("milestonesDetails");
  const [data, setData] = useState<any>([]);

  const [milestoneId, setMilestoneId] = useState(params.id);
  const tabs = [
    { label: "Detailed Information", value: "milestonesDetails", tagText: "Detailed Information" },
  ];

  const getData = (id: any) => {
    getMilestoneDetails(id).then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    getData(milestoneId);
  }, []);
  return (
    <div>
      <CustomBreadCrumbs
        data={[
          { title: "Projects", link: "/project-list" },
          { title: data?.work?.title ?? "--", link: `/project/${data?.work_id}` },
          { title: data?.name ?? "--" },
        ]}
      />

      <div className="customCardContainer bg-bgWhite !shadow-none !rounded-lg flex flex-col flex-1">
        <Tabs
          ariaLabel="consultation tabs"
          setTab={setTab}
          tab={tab}
          tabs={tabs}
        />

        {tab == "milestonesDetails" && (
          <div className="content tabContent">
            <div className="flex flex-col">
            <div className=" flex items-center justify-start w-full bg-tableHeader p-1.5 px-3">
                <p className="text-sm font-semibold">Milestone Details</p>
              </div>
              <div className="grid gap-4 p-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Milestone ID</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data?.display_id ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Milestone Title</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data?.name ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Target Date</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                    {data?.target_date ? moment(data?.target_date).format("DD-MM-YYYY") : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Amount</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                    {data?.currency?.name ?? ""} {data?.cost ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Milestone Status</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data?.status_display_attributes?.displayName ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Milestone Description</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data?.description ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText"> Created Date </label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data?.created_at ? moment(data?.created_at).format("DD-MM-YYYY") : "--"}
                    </p>
                  </div>
                </div>
              </div>
              <div className=" flex items-center justify-start w-full bg-tableHeader p-1.5 px-3">
                <p className="text-sm font-semibold">Additional Details</p>
              </div>
              <div className="grid gap-4 p-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Deposit Status</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data?.milestone_deposit_status ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Deposit Date</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                    {data?.deposit_date ? moment(data?.deposit_date).format("DD-MM-YYYY") : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Payout Status</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                      {data?.payout_status ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Payout Date</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                    {data?.payout_request?.payout_date ? moment(data?.payout_request?.payout_date).format("DD-MM-YYYY") : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Dispute Status</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm min-h-[20px]">
                    {data?.dispute?.dispute_status ?? "--"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
