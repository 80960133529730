import { getData } from "../../../apis/api.helpers";
import apiUrl from "./api.url";

export const getWorkTransactionList = (
  perPage: number,
  page: number,
  search?: string,
  filters?: string
) =>
  getData(
    `${apiUrl.WORK_TRANSACTION_LIST}per_page=${perPage}&page=${page}&search=${search}${filters}`
  ).then((res) => res.data);

export const getWorkTransactionDetails = (id: string) =>
  getData(`${apiUrl.WORK_TRANSACTION_DETAILS}/${id}`).then((res) => res.data);
