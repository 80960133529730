import Button from "@mui/material/Button";

type Props = {
  className?: string;
  title?: string;
  onClick?: (event?: any) => void;
  href?: string;
  target?: string;
  tooltipText?: string;
};
export default function CustomButton(props: Props) {
  const { className, title, onClick, href, tooltipText, ...otherProps } = props;
  return (
    <Button
      variant="contained"
      onClick={onClick}
      href={href}
      {...otherProps}
      title={tooltipText}
      className={`${className} bg-primary text-bgWhite h-8 text-sm capitalize min-w-[72px] !rounded-md`}
    >
      {title}
    </Button>
  );
}
