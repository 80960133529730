import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import DataFields from "./components/datafieldRoute";
import GuestRoute from "./components/guestRoute";
import userList from "./components/userListRoute";
import UserRoute from "./components/userRoute";

const Dashboard = lazy(() => import("../pages/dashboard/dashboard"));
const User = lazy(() => import("../pages/user/user"));

const Profile = lazy(() => import("../components/Profile"));

const Login = lazy(() => import("../pages/userManagement/login/index"));
const Users = lazy(() => import("../pages/datafields/index"));
const Register = lazy(() => import("../pages/userManagement/register/index"));
const ForgotPassword = lazy(
  () => import("../pages/userManagement/forgotpassword/index")
);

const ResetPassword = lazy(
  () => import("../pages/userManagement/resetpassword/index")
);
const EmailVerification = lazy(
  () => import("../pages/userManagement/verifyemail/index")
);
type Props = {
  authenticated?: boolean;
};
export default function MainRoutes(props: Props) {
  return (
    <>
      <Routes>
        {[...DataFields].map((route) => (
          <Route
            element={route?.props.element}
            key={route?.props.path}
            path={route?.props.path}
          />
        ))}
        {[...userList].map((route) => (
          <Route
            element={route?.props.element}
            key={route?.props.path}
            path={route?.props.path}
          />
        ))}
        
        <Route
          element={
            <UserRoute>
              <Dashboard />
            </UserRoute>
          }
          path="/dashboard"
          key="/dashboard"
        />
        <Route
          element={
            <UserRoute>
              <Dashboard />
            </UserRoute>
          }
          key="*"
          path="*"
        />
        <Route
          element={
            <GuestRoute>
              <Login />
            </GuestRoute>
          }
          path="/login"
          key="/login"
        />
        <Route
          element={
            <GuestRoute>
              <ForgotPassword />
            </GuestRoute>
          }
          path="/forgotpassword"
          key="/forgotpassword"
        />
        <Route
          element={
            <GuestRoute>
              <ResetPassword />
            </GuestRoute>
          }
          path="/reset-password/:id"
          key="/reset-password/:id"
        />
        <Route
          element={
            <GuestRoute>
              <EmailVerification />
            </GuestRoute>
          }
          path="/verify-account/:id"
          key="/verify-account/:id"
        />
        <Route
          element={
            <UserRoute>
              <Profile />
            </UserRoute>
          }
          path="/profile"
          key="/profile"
        />
      </Routes>
    </>
  );
}
