const PROJECT_REPORT_LIST = "admin/list-reports";
const PROJECT_REPORT_DETAILS = "/admin/get-project-reports"
const USER_REPORT_LIST = "admin/list-user-reports";
const USER_REPORT_DETAILS = "/admin/get-user-reports"
const CHANGE_RESOLUTION_STATUS="/admin/resolve-report"

const apiUrl = {
  PROJECT_REPORT_LIST,
  PROJECT_REPORT_DETAILS,
  USER_REPORT_LIST,
  USER_REPORT_DETAILS,
  CHANGE_RESOLUTION_STATUS
};

export default apiUrl;