import { z, ZodDate } from "zod";

export const reportingSchema = z.object({
  resolution_status: z
    .string({
      required_error: "Enter Resolution Status",
    })
    .trim()
    .min(1, { message: "Enter Resolution Status" }),
  comment: z
    .string({
      required_error: "Enter Comment",
    })
    .trim()
    .min(1, { message: "Enter Comment" }),
});
