import UserRoute from "./userRoute";
import { lazy } from "react";
import { Route } from "react-router-dom";
const DataFieldCompoenent = lazy(() => import("../../pages/datafields/index"));
const DetailView = lazy(
  () => import("../../pages/datafields/components/detailView")
);

const DataFields = [
  <Route
    element={
      <UserRoute>
        <DataFieldCompoenent />
      </UserRoute>
    }
    path="/datafields"
    key="/datafields"
  />,
  <Route
    element={
      <UserRoute>
        <DetailView />
      </UserRoute>
    }
    path="/datafields/:id"
    key="/datafields/:id"
  />,
];
export default DataFields;
