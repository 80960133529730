const PAYOUT_LIST = "admin/payouts/list?";
const PAYOUT_DETAILS = "/admin/payouts";
const SETTLE_PAYOUT = (payoutId: string) => {
  return `/admin/payouts/${payoutId}/settle`;
}
const EXPORT_PAYOUT_LIST = "admin/payouts/export?";

const apiUrl = {
  PAYOUT_LIST,
  PAYOUT_DETAILS,
  SETTLE_PAYOUT,
  EXPORT_PAYOUT_LIST,
};

export default apiUrl;
