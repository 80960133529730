import { Tabs as MuiTabs, Tab } from "@mui/material";
import styles from "./Tabs.module.scss";
import { RxExclamationTriangle } from "react-icons/rx"

type Props = {
  ariaLabel: string;
  setTab: (tab: any) => void;
  tab: string | null;
  tabs: {
    label: string;
    figure?: number;
    value: string | null;
    count?: any;
    tagText?: any;
    disabled?: boolean
  }[];
};

const Tabs = (props: Props) => {
  const { ariaLabel, setTab, tab, tabs } = props;

  return (
    <>
      <MuiTabs
        aria-label={ariaLabel}
        className={`${styles.tabs} customTabs`}
        onChange={(_, newValue) => setTab(newValue)}
        scrollButtons="auto"
        value={tab}
        variant="scrollable"
      >
        {tabs.map((mappedTab) => (
          <Tab
            classes={{ root: styles.tab, selected: styles.selected }}
            key={mappedTab.label}
            label={
              mappedTab.count >= 0 ? (
                <span>
                  {mappedTab.label} ({mappedTab.count})
                </span>
              ) : (
                <span className={`flex ${mappedTab?.disabled ? "text-secondary" : ""}`}>{mappedTab.label}</span>
              )
            }
            value={mappedTab.value}
          />
        ))}
      </MuiTabs>
    </>
  );
};

export default Tabs;
