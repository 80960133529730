import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import "./fieldstyle.scss";
type Props = {
  fullWidth?: boolean;
  required?: boolean;
  label?: string;
  value?: any;
  handleDateChange: (date: unknown, name: string) => void;
  register?: any;
  placeholder?: string;
  name?: string;
  disableFuture?: boolean;
  errormsg?: string;
};
export default function CustomDatePicker(props: Props) {
  const { label, required, register, value, name, disableFuture, errormsg } = props;
  const handleDateChange = (newValue: any) => {
    if (newValue) {
      newValue = dayjs(newValue).format("YYYY-MM-DD");
    }
    props.handleDateChange(newValue, name ?? "");
  };
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {label && (
          <label className="block justify-start text-xs mb-1">
            {" "}
            {required ? `${label}*` : label}
          </label>
        )}
        <DatePicker
          label=""
          {...register}
          value={value ? dayjs(value) : null}
          name={name}
          className="customField"
          onChange={(newValue) => {
            handleDateChange(newValue);
          }}
          renderInput={(params) => <TextField {...props} {...params} />}
          disableFuture={disableFuture}
          inputFormat="DD-MM-YYYY"
        />
        {errormsg && <span className="text-sm text-red-500">{errormsg}</span>}
      </LocalizationProvider>
    </div>
  );
}
