import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import CustomTable from "../../customComponents/table/table";
import { useDataFieldStore } from "../../store/datafields";
import { useLoaderStore } from "../../store/loaderStore";
import { FilterDataType } from "../../customComponents/table/components/commontypes";
import moment from "moment";
import { getWorkTransactionList } from "./apis/api.actions";

export default function WorkTransactions(props: any) {
  const [data, setData] = useState<any>([]);
  const { setDataFieldsitem } = useDataFieldStore();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState<any>(25);
  const [newPage, setNewPage] = useState<any>(1);
  const [totalCount, setTotalCount] = useState<any>();
  const [searchValue, setSearchValue] = useState<string | undefined>("");
  const [appliedFilters, setAppliedFilters] = useState("");
  const [filterData, setFilterData] = useState<FilterDataType>();

  const handleLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    setDataFieldsitem({
      name: `${row.display_id}`,
      key: row.id,
    });
    navigate(`/work-transaction/${row.id}`);
  };

  const handleProjectLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    if (row.work?.id) {
      setDataFieldsitem({
        name: row.work?.title,
        key: row.work?.id,
      });

      navigate(`/project/${row.work?.id}`);
    }
  };

  const handlePayoutToLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    if (row?.bid?.company) {
      setDataFieldsitem({
        name: row?.bid?.company?.name,
        key: row?.bid?.company?.id,
      });

      navigate(`/company/${row?.bid?.company?.id}`);
    } else {
      setDataFieldsitem({
        name: row?.bid?.created_by?.name,
        key: row?.bid?.created_by?.id,
      });

      navigate(`/user/${row?.bid?.created_by?.id}`);
    }
  };

  const handlePaidByLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    if (row?.work?.projectOwnerType)
    {
      if (row?.work?.projectOwnerType === "Individual") {
        setDataFieldsitem({
          name: row?.work?.projectOwner?.name,
          key: row?.work?.projectOwner?.id,
        });
  
        navigate(`/user/${row?.work?.projectOwner?.id}`);
      } else {
        setDataFieldsitem({
          name: row?.work?.projectOwner?.name,
          key: row?.work?.projectOwner?.id,
        });
  
        navigate(`/company/${row?.work?.projectOwner?.id}`);
      }
    }
  };

  const columns = [
    {
        title: "Transaction ID",
        field: "display_id",
        type: "string",
        minWidth: 120,
        render: (row: any) => (
          <Link to={``} onClick={(e) => handleLink(e, row)}>
            {row.display_id}
          </Link>
        ),
      },
      {
        title: "Transaction Date",
        field: "created_at",
        type: "date",
        minWidth: 120,
      },
    {
      title: "Transaction Type",
      field: "transaction_type",
      type: "string",
      minWidth: 120,
    },
    {
      title: "Paid By",
      field: "paid_by",
      type: "string",
      minWidth: 120,
      textStyle: false,
      render: (row: any) => {
        if (row?.work?.projectOwner)
        {
            return (
              <Link className="hover:underline text-[#1650e2]" to={``} onClick={(e) => handlePaidByLink(e, row)}>
                {row?.work?.projectOwner?.name}
              </Link>
            )
        } else {
          return "--"
        }
      },
    },
    {
      title: "Project Name",
      field: "work",
      type: "object",
      objectKey: "title",
      minWidth: 120,
      render: (row: any) => {
        return row?.work?.work_type?.slug === "gig" ? (<Link to={``} onClick={(e) => handleProjectLink(e, row)}>
          {row.work?.title}
        </Link>) : (
          <span>{row.work?.title}</span>
        )
      },
    },
    {
      title: "Milestone Name",
      field: "milestone",
      type: "object",
      objectKey: "name",
      minWidth: 120,
    },
    {
      title: "Amount Paid",
      field: "work",
      type: "string",
      minWidth: 200,
      textStyle: false,
      render: (row: any) => (
        <p>{row.currency} {row.amount}</p>
      ),
    },
    {
      title: "Deposit Status",
      field: "payment_status",
      type: "string",
      minWidth: 120,
    },
    {
      title: "Deposit Date",
      field: "created_at",
      type: "date",
      minWidth: 120,
    },
    {
      title: "Payout ID",
      field: "payout",
      type: "object",
      objectKey: "display_id",
      minWidth: 120,
    },
    {
      title: "Payout To",
      field: "payout_to",
      type: "string",
      minWidth: 120,
      textStyle: false,
      render: (row: any) => {
        if (row?.bid)
        {
          if (row?.bid?.company)
          {
            return (
              <Link className="hover:underline text-[#1650e2]" to={``} onClick={(e) => handlePayoutToLink(e, row)}>
                {row?.bid?.company?.name}
              </Link>
            )
          } else {
            return (
              <Link className="hover:underline text-[#1650e2]" to={``} onClick={(e) => handlePayoutToLink(e, row)}>
                {row?.bid?.created_by?.name}
              </Link>
            )
          }
        }
      },
    },
    {
      title: "Payout Date",
      field: "payout_date",
      type: "date",
      minWidth: 120,
      textStyle: false,
      render: (row: any) => (
        <p>{row.payout?.payout_request?.payout_date ? moment(row.payout?.payout_request?.payout_date).format("DD-MM-YYYY") : ""}</p>
      ),
    },
    {
      title: "Payout Status",
      field: "payout_status",
      type: "string",
      minWidth: 120,
      textStyle: false,
      render: (row: any) => (
        <p>{row.payout?.payout_request?.status ?? ""}</p>
      ),
    },
  ];

  let actionProps = [
    {
      actionTitle: "View",
      action: (data: any) => navigate(`/work-transaction/${data.id}`),
      actionIcon: "view",
    },
  ];

  const { setLoader, loader } = useLoaderStore();
  const { enqueueSnackbar } = useSnackbar();
  const getDataFields = (
    rows_per_page: number,
    new_page: number,
    search?: string
  ) => {
    setLoader(true);
    getWorkTransactionList(
      rows_per_page,
      new_page,
      search ?? searchValue,
      appliedFilters
    )
      .then((res) => {
        setTotalCount(res.total);
        setData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    setNewPage(1)
    getDataFields(rowsPerPage, newPage);
  }, [appliedFilters]);

  const handlePagination = (new_page: number, rows_per_page: number) => {
    setNewPage(new_page);
    setRowsPerPage(rows_per_page);
    getDataFields(rows_per_page, new_page);
  };

  const handleGlobalSearch = (search?: string) => {
    setNewPage(1)
    setSearchValue(search);
    getDataFields(rowsPerPage, 1, search);
  };

  useEffect(() => {
    setFilterData({
      primary: [
        {
          title: "Created At",
          field: "created_at",
          type: "daterange",
          value: [],
          maxDate: new Date(),
        },
      ],
      secondary: [],
    });
  }, []);

  function handleFilterSubmit(values: FilterDataType) {
    let createdAtVal =
      values.primary.find((filter) => filter.field === "created_at")?.value ??
      [];
    if (
      Array.isArray(createdAtVal) &&
      createdAtVal[0] &&
      createdAtVal[1] &&
      moment(createdAtVal[0]).isValid() &&
      moment(createdAtVal[1]).isValid()
    ) {
      createdAtVal = `${moment(createdAtVal[0]).format(
        "YYYY-MM-DD"
      )} - ${moment(createdAtVal[1]).format("YYYY-MM-DD")}`;
    } else {
      createdAtVal = "";
    }
    let filterString = "";
    filterString = `${
      createdAtVal.length > 0 ? `&filter[created_at]=${createdAtVal}` : ""
    }`;
    setAppliedFilters(filterString);
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTable
            data={data}
            columns={columns}
            title={"Project Transactions"}
            maxBodyHeight="calc(100Vh - 237px)"
            minBodyHeight="calc(100Vh - 237px)"
            search={true}
            pagination={true}
            customFilter={true}
            filterData={filterData}
            filterSubmit={handleFilterSubmit}
            isLoading={loader}
            handlePagination={handlePagination}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            page={newPage}
            actionProps={actionProps}
            searchValue={searchValue}
            handleGlobalSearch={handleGlobalSearch}
          />
        </Grid>
      </Grid>
    </div>
  );
}
