import { MenuItem } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";

import styles from "./index.module.scss";

type Props = {
  className?: string;
  label?: string;
  data?: { id: string; label: string }[] | any;
  onChange: (value: any) => void;
  value?: string | string[];
  fullWidth?: boolean;
  register?: any;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  multiple?: boolean;
};

const SelectField = (props: Props) => {
  const {
    label,
    data,
    register,
    placeholder,
    onChange,
    value,
    required,
    error,
    className,
  } = props;

  return (
    <div>
      {label && required ? (
        <label className=" justify-start labelText">
          {" "}
          {label} <span className="text-red">*</span>
        </label>
      ) : (
        <label className=" justify-start labelText"> {label}</label>
      )}
      <div className={styles.inputGroup}>
        <Select
          {...props}
          className={` ${className} customField text-sm`}
          fullWidth
          value={value ?? ""}
          {...register}
          onChange={(e: object | any) => onChange(e.target.value)}
          size="small"
          error={error}
          helperText={error}
          label={""}
        >
          {data &&
            data.length &&
            data.map((item: any) => (
              <MenuItem
                key={item?.id}
                value={item?.id}
                className={styles.textSmall}
              >
                {item.label ?? item.name}
              </MenuItem>
            ))}
        </Select>
        {error && <FormHelperText error={true}>{error}</FormHelperText>}
      </div>
    </div>
  );
};

export default SelectField;
