import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import CustomTable from "../../customComponents/table/table";
import { useDataFieldStore } from "../../store/datafields";
import { useLoaderStore } from "../../store/loaderStore";
import { FilterDataType } from "../../customComponents/table/components/commontypes";
import { getWorkSectors } from "../../apis/pick-list/list.api";
import moment from "moment";
import { exportPayoutList, getPayoutList, settlePayout } from "./apis/api.actions";
import CustomDialogBox from "../../customComponents/dialogbox";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { payoutSchema } from "./schema";
import CustomInput from "../../customComponents/textfield";
import CustomDatePicker from "../../customComponents/datepicker";

export default function PayoutRequests(props: any) {
  const [data, setData] = useState<any>([]);
  const { setDataFieldsitem } = useDataFieldStore();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState<any>(25);
  const [newPage, setNewPage] = useState<any>(1);
  const [totalCount, setTotalCount] = useState<any>();
  const [searchValue, setSearchValue] = useState<string | undefined>("");
  const [appliedFilters, setAppliedFilters] = useState("");
  const [filterData, setFilterData] = useState<FilterDataType>();
  const [showPayoutSettlementPopup, setShowPayoutSettlementPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [statusList, setStatusList] = useState([
    {id: "Requested", label: "Requested"},
    {id: "Cancelled", label: "Cancelled"},
    {id: "Rejected", label: "Rejected"},
    {id: "Approved", label: "Approved"},
    {id: "Settled", label: "Settled"},
  ]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setError,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      'transaction_id': "",
      'amount': "",
      'payout_date': ""
    },
    resolver: zodResolver(payoutSchema),
    mode: "onChange",
  });

  const handleLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    setDataFieldsitem({
      name: row.display_id,
      key: row.id,
    });
    navigate(`/payout/${row.id}`);
  };

  const handleWorkLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    setDataFieldsitem({
      name: row.work?.title,
      key: row.work?.id,
    });
    if (row?.work?.work_type?.slug)
    {
        if (row?.work?.work_type?.slug === "gig")
        {
            navigate(`/project/${row.work?.id}`);
        }
    }
  };

  const handlePayoutToLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
          if (row?.milestone?.company)
          {
            setDataFieldsitem({
              name: row?.milestone?.company?.name,
              key: row?.milestone?.company?.id,
            });
      
            navigate(`/company/${row?.milestone?.company?.id}`);
          } else {
            setDataFieldsitem({
              name: row?.milestone?.created_by?.name,
              key: row?.milestone?.created_by?.id,
            });
      
            navigate(`/user/${row?.milestone?.created_by?.id}`);
          }
  };

  const columns = [
    {
      title: "Request ID",
      field: "display_id",
      type: "string",
      minWidth: 120,
      render: (row: any) => (
        <Link to={``} onClick={(e) => handleLink(e, row)}>
          {row.display_id}
        </Link>
      ),
    },
    {
      title: "Request Date",
      field: "created_at",
      type: "date",
      minWidth: 120,
    },
    {
        title: "Project ID",
        field: "work",
        type: "object",
        objectKey: "display_id",
        minWidth: 120,
        render: (row: any) => (
          <Link to={``} onClick={(e) => handleWorkLink(e, row)}>
            {row.work?.display_id}
          </Link>
        ),
      },
    {
      title: "Project Name",
      field: "work",
      type: "object",
      objectKey: "title",
      minWidth: 200,
    },
    {
      title: "Milestone ID",
      field: "milestone",
      type: "object",
      objectKey: "display_id",
      minWidth: 200,
    },
    {
      title: "Milestone Name",
      field: "milestone",
      type: "object",
      objectKey: "name",
      minWidth: 200,
    },
    {
        title: "Milestone Amount",
        field: "amount",
        type: "number",
        minWidth: 200,
      },
      {
        title: "Payout To",
        field: "status",
        type: "string",
        minWidth: 120,
        render: (row: any) => {
          let payoutEntity = null;
          if (row?.milestone?.company)
          {
            payoutEntity = row?.milestone?.company
          } else {
            payoutEntity = row?.milestone?.created_by
          }
          return payoutEntity ? (
          <Link to={``} onClick={(e) => handlePayoutToLink(e, row)}>
            {payoutEntity?.display_id}
          </Link>
        ) : ""},
      },
      {
        title: "Payout Settled On",
        field: "payout_date",
        type: "date",
        minWidth: 120,
      },
    {
      title: "Payout Status",
      field: "status",
      type: "string",
      minWidth: 120,
    },
  ];

  let actionProps = [
    {
      actionTitle: "View",
      action: (data: any) => navigate(`/payout/${data.id}`),
      actionIcon: "view",
    },
    {
      actionTitle: "Mark as Settled",
      action: handlePayoutSettlement,
      actionIcon: "mark_as_settled",
      show: (data: any) => data?.status === "In Progress",
    },
  ];

  function handlePayoutSettlement(data: any)
  {
    setSelectedRow(data)
    setValue("amount", data.amount)
    setShowPayoutSettlementPopup(true)
  }

  const { setLoader, loader } = useLoaderStore();
  const { enqueueSnackbar } = useSnackbar();
  const getDataFields = (
    rows_per_page: number,
    new_page: number,
    search?: string
  ) => {
    setLoader(true);
    getPayoutList(
      rows_per_page,
      new_page,
      search ?? searchValue,
      appliedFilters
    )
      .then((res) => {
        setTotalCount(res.total);
        setData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  };

  function exportBomData(search?: string) {
    setLoader(true);
    exportPayoutList('bom',
      search ?? searchValue,
      appliedFilters
    )
      .then((res) => {
        const outputFilename = `Payout Requests - ${moment().format(
          "DD-MM-YYYY"
        )}.xlsx`;
  
        // If you want to download file automatically using link attribute.
        var blob = new Blob([res]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = outputFilename;
  
        document.body.appendChild(link);
  
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  }

  function exportOthersData(search?: string) {
    setLoader(true);
    exportPayoutList('others',
      search ?? searchValue,
      appliedFilters
    )
      .then((res) => {
        const outputFilename = `Payout Requests - ${moment().format(
          "DD-MM-YYYY"
        )}.xlsx`;
  
        // If you want to download file automatically using link attribute.
        var blob = new Blob([res]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = outputFilename;
  
        document.body.appendChild(link);
  
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  }

  function exportInternationalData(search?: string) {
    setLoader(true);
    exportPayoutList('intl',
      search ?? searchValue,
      appliedFilters
    )
      .then((res) => {
        const outputFilename = `Payout Requests - ${moment().format(
          "DD-MM-YYYY"
        )}.xlsx`;
  
        // If you want to download file automatically using link attribute.
        var blob = new Blob([res]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = outputFilename;
  
        document.body.appendChild(link);
  
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  }

  useEffect(() => {
    setNewPage(1);
    getDataFields(rowsPerPage, 1);
  }, [appliedFilters]);

  const handlePagination = (new_page: number, rows_per_page: number) => {
    setNewPage(new_page);
    setRowsPerPage(rows_per_page);
    getDataFields(rows_per_page, new_page);
  };

  const handleGlobalSearch = (search?: string) => {
    setNewPage(1);
    setSearchValue(search);
    getDataFields(rowsPerPage, 1, search);
  };

  useEffect(() => {
    setFilterData({
      primary: [
        {
          title: "Created At",
          field: "created_at",
          type: "daterange",
          value: [],
          maxDate: new Date(),
        },
        {
          title: "Status",
          field: "status",
          type: "select",
          options: statusList,
          multiple: true,
          value: [],
        },
      ],
      secondary: [],
    });
  }, []);

  function handleFilterSubmit(values: FilterDataType) {
    let createdAtVal =
      values.primary.find((filter) => filter.field === "created_at")?.value ??
      [];
    if (
      Array.isArray(createdAtVal) &&
      createdAtVal[0] &&
      createdAtVal[1] &&
      moment(createdAtVal[0]).isValid() &&
      moment(createdAtVal[1]).isValid()
    ) {
      createdAtVal = `${moment(createdAtVal[0]).format(
        "YYYY-MM-DD"
      )} - ${moment(createdAtVal[1]).format("YYYY-MM-DD")}`;
    } else {
      createdAtVal = "";
    }
    let statusVal =
      values.primary.find((filter) => filter.field === "status")?.value ??
      null;
    if (Array.isArray(statusVal)) {
      statusVal = statusVal.join();
    }
    let filterString = "";
    filterString = `${
      createdAtVal.length > 0 ? `&filter[created_at]=${createdAtVal}` : ""
    }${
      statusVal ? `&filter[status]=${statusVal}` : ""
    }`;
    setAppliedFilters(filterString);
  }

  function handlePayoutSettlePopupClose() {
    setShowPayoutSettlementPopup(false);
    setSelectedRow(undefined);
  }

  function handlePayoutSettleConfirmed()
  {
    handleSubmit(handleValidatedData)();
  }

  function handleValidatedData(formData: any) {
    let payload: any = {
      transaction_id: formData?.transaction_id,
      amount: formData?.amount,
      payout_date: formData?.payout_date,
    };

    settlePayout(selectedRow.id, payload)
    .then((res) => {
      getDataFields(rowsPerPage, newPage);
      enqueueSnackbar(res.message, {
        variant: res.status === true ? "success" : "error",
      });
    })
    .catch((err) => {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: "error",
      });
    });
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTable
            data={data}
            columns={columns}
            title={"Payout Processing"}
            maxBodyHeight="calc(100Vh - 237px)"
            minBodyHeight="calc(100Vh - 237px)"
            search={true}
            pagination={true}
            customFilter={true}
            filterData={filterData}
            filterSubmit={handleFilterSubmit}
            isLoading={loader}
            handlePagination={handlePagination}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            page={newPage}
            actionProps={actionProps}
            searchValue={searchValue}
            handleGlobalSearch={handleGlobalSearch}
            exportButton={{
              title: "Export BM Payouts",
              action: exportBomData,
              tooltipText: "Export Accepted Requests",
            }}
            customButtons={[
              {
                title: "Export Other Payouts",
                action: exportOthersData,
                tooltipText: "Export Accepted Requests",
              },
              {
                title: "Export International Payouts",
                action: exportInternationalData,
                tooltipText: "Export Accepted Requests",
              },
            ]}
          />
        </Grid>
      </Grid>
      <CustomDialogBox
        open={showPayoutSettlementPopup}
        title="Settle Payout Request"
        handleClose={handlePayoutSettlePopupClose}
        primaryButton={{
          title: "Update",
          action: handlePayoutSettleConfirmed,
        }}
        secondaryButton={{
          title: "Cancel",
          action: handlePayoutSettlePopupClose,
        }}
        maxWidth="lg"
      >
        <div className="grid grid-cols-1 gap-4 p-2">
          <div className="col-start-1 col-span-1">
          <Controller
            name="transaction_id"
            control={control}
            render={({ field }) => {
              return (
                <CustomInput
                  label="Transaction ID"
                  required={true}
                  error={errors?.transaction_id?.message}
                  {...field}
                />
              );
            }}
          />
          </div>
          <div className="col-start-1 col-span-1">
          <Controller
            name="amount"
            control={control}
            render={({ field }) => {
              return (
                <CustomInput
                  label="Amount"
                  required={true}
                  disabled={true}
                  error={errors?.amount?.message}
                  {...field}
                />
              );
            }}
          />
          </div>
          <div className="col-start-1 col-span-1">
          <Controller
            name="payout_date"
            control={control}
            render={({ field }) => {
              return (
                <CustomDatePicker
                      label="Payout Date"
                      disableFuture={true}
                      required={true}
                      handleDateChange={field.onChange}
                      errormsg={errors?.payout_date?.message}
                      {...field}
                    />
              );
            }}
          />
          </div>
        </div>
      </CustomDialogBox>
    </div>
  );
}
