const REVIEW_LIST = "admin/list-rating";
const REVIEW_DETAILS = "/admin/rating-details"
const DELETE_REVIEW = "admin/delete-rating"

const apiUrl = {
  REVIEW_LIST,
  REVIEW_DETAILS,
  DELETE_REVIEW,
};

export default apiUrl;
