import FilterListIcon from "@mui/icons-material/FilterList";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";

import CustomButton from "../../button/index";
import { FilterDataType, FilterItem } from "./commontypes";
import FilterComponents from "./filterComponents";

type Anchor = "top" | "left" | "bottom" | "right";
type Props = {
  filterData?: FilterDataType;
  filterSubmit?: (values: FilterDataType) => void;
  filterClear?: () => void;
  handleSubmit: any;

  fields?: FilterItem[];
};

let filterResetKey = 1;
export default function FilterComponent(props: Props) {
  const { fields, handleSubmit, filterClear } = props;
  const { reset } = useFormContext();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
  const handleData = (data: any) => {
    if (props.filterSubmit && data.filterData) {
      props.filterSubmit(data.filterData);
    }
  };

  function handleClear() {
    if (filterClear) {
      filterClear();
    }
    filterResetKey = filterResetKey + 1;
  }
  return (
    <div className="h-8 flex items-center border border-formBorder rounded">
      <React.Fragment key={"right"}>
        <Tooltip title="Filter list">
          <IconButton
            disableRipple
            onClick={toggleDrawer("right", true)}
            className="p-o pl-2 w-8 h-8 "
          >
            <FilterListIcon className="text-xl" />
          </IconButton>
        </Tooltip>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          <Box
            sx={{
              width: 400,
            }}
            className="relative h-full flex flex-col"
            role="presentation"
          >
            <div className="flex justify-between p-4 shadow-md">
              <div className="flex  font-medium text-lg items-center">
                <p className="text-xl">Filter </p>
              </div>
              <div>
                <IconButton
                  disableRipple
                  onClick={toggleDrawer("right", false)}
                  className="p-0 ml-2"
                >
                  <AiOutlineClose className="p-0  text-primary text-xl" />
                </IconButton>
              </div>
            </div>
            <Divider />
            <List className="p-4 flex-1">
              {fields?.map((item, index) => (
                <ListItem key={item.title} className="block p-0 mb-4">
                  <FilterComponents
                    data={item}
                    path={`filterData.primary.${index}`}
                  />
                </ListItem>
              ))}
            </List>
            <div className=" bottom-0 p-2 justify-between flex  w-full">
              <CustomButton
                onClick={(event) => {
                  handleSubmit(handleData)();
                  toggleDrawer("right", false)(event);
                }}
                title="Apply"
                className="mr-2  w-1/2 h-8"
              />
              <CustomButton
                onClick={(event) => {
                  reset();
                  handleClear();
                  handleSubmit(handleData)();
                  toggleDrawer("right", false)(event);
                }}
                title="Cancel"
                className="w-1/2 h-8"
              />
            </div>
          </Box>{" "}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
