import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";

import CustomBreadCrumbs from "../../components/breadcrumbs/index";
import Tabs from "../../customComponents/Tabs/Tabs";
import { useDataFieldStore } from "../../store/datafields";
import Favourite from "../../components/favourite/index";
import moment from "moment";
import { getWorkTransactionDetails } from "./apis/api.actions";
import { capitalize } from "../../utilities/format";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function WorkTransactionDetailedView(props: any) {
  const { datafieldsItem, setDataFieldsitem } = useDataFieldStore();
  const params = useParams();
  const navigate = useNavigate();

  const [tab, setTab] = useState("transactionDetails");
  const [data, setData] = useState<any>([]);
  const [favouriteData, setFavoriteData] = useState<any>([]);

  const [transactionId, setTransactionId] = useState(params.id);
  const tabs = [
    {
      label: "Detailed Information",
      value: "transactionDetails",
      tagText: "Detailed Information",
    },
  ];

  const getData = (id: any) => {
    getWorkTransactionDetails(id).then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    getData(transactionId);
  }, []);

  const handlePayoutToLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    if (row?.bid?.company) {
      setDataFieldsitem({
        name: row?.bid?.company?.name,
        key: row?.bid?.company?.id,
      });

      navigate(`/company/${row?.bid?.company?.id}`);
    } else {
      setDataFieldsitem({
        name: row?.bid?.created_by?.name,
        key: row?.bid?.created_by?.id,
      });

      navigate(`/user/${row?.bid?.created_by?.id}`);
    }
  };

  const handlePaidByLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    if (row?.work?.projectOwnerType)
    {
      if (row?.work?.projectOwnerType === "Individual") {
        setDataFieldsitem({
          name: row?.work?.projectOwner?.name,
          key: row?.work?.projectOwner?.id,
        });
  
        navigate(`/user/${row?.work?.projectOwner?.id}`);
      } else {
        setDataFieldsitem({
          name: row?.work?.projectOwner?.name,
          key: row?.work?.projectOwner?.id,
        });
  
        navigate(`/company/${row?.work?.projectOwner?.id}`);
      }
    }
  };

  const handleProjectLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    if (row.work?.id) {
      setDataFieldsitem({
        name: row.work?.title,
        key: row.work?.id,
      });

      navigate(`/project/${row.work?.id}`);
    }
  };

  return (
    <div>
      <CustomBreadCrumbs
        data={[
          { title: "Work Transactions", link: "/work-transaction-list" },
          { title: datafieldsItem.name },
        ]}
      />
      <div className=" bg-bgWhite customCardContainer !shadow-none !rounded-lg flex flex-col flex-1">
        <Tabs
          ariaLabel="consultation tabs"
          setTab={setTab}
          tab={tab}
          tabs={tabs}
        />
        {tab === "transactionDetails" && (
          <div className="content tabContent ">
            <div className="flex flex-col">
              <div className="p-1.5 px-3 flex items-center justify-start w-full bg-tableHeader">
                <p className="text-sm font-semibold">Transaction Details</p>
              </div>
              <div className="grid gap-4 p-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Transaction ID</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.display_id ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Transaction Date</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.created_at
                        ? moment(data.created_at).format("DD-MM-YYYY")
                        : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Transaction Type</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data?.transaction_type ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Paid By</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                    {data.work?.projectOwner ? (
                        (<Link className="hover:underline text-[#1650e2]" to={``} onClick={(e) => handlePaidByLink(e, data)}>
                        {data.work?.projectOwner?.name ?? "--"}
                      </Link>)
                      ) : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Project Name</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                    {data?.work?.title ? (
                        (<Link className="hover:underline text-[#1650e2]" to={``} onClick={(e) => handleProjectLink(e, data)}>
                        {data?.work?.title ?? "--"}
                      </Link>)
                      ) : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Milestone Name</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.milestone?.name}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Amount Paid</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.currency ?? ""} {data.amount ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Platform Fee</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.currency ?? ""} {data.dawam_commission ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Work Deposit Deduction</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.currency ?? ""} {data.work_deposit_deduction ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Payable Amount</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.currency ?? ""} {data.sub_total ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Transaction Charge</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.currency ?? ""} {data.transaction_charge ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Tax Amount</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.currency ?? ""} {data.tax_amount ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Net Amount</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.currency ?? ""} {data.net_amount ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Deposit Status</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.payment_status ?? "--"}
                    </p>
                  </div>
                </div>
              </div>
              {
                data?.transaction_type_key === 'milestone_deposit' && (
                    <>
                      <div className="p-1.5 px-3 flex items-center justify-start w-full bg-tableHeader">
                        <p className="text-sm font-semibold">Payout Details</p>
                      </div>
                      <div className="grid gap-4 p-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                        <div className="col-span-1 ">
                          <div className="flex flex-col">
                            <label className="labelText">Payout ID</label>
                          </div>
                          <div className="flex flex-col">
                            <p className="text-sm detailtext min-h-[20px]">
                              {data?.payout?.display_id ?? "--"}
                            </p>
                          </div>
                        </div>
                        <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Payout To</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                    {data.bid?.company ? (
                        <Link className="hover:underline text-[#1650e2]" to={``} onClick={(e) => handlePayoutToLink(e, data)}>
                        {data?.bid?.company?.name ?? "--"}
                      </Link>
                      ) : (<Link className="hover:underline text-[#1650e2]" to={``} onClick={(e) => handlePayoutToLink(e, data)}>
                        {data?.bid?.created_by?.name ?? "--"}
                      </Link>
                      )}
                    </p>
                  </div>
                </div>
                        <div className="col-span-1 ">
                          <div className="flex flex-col">
                            <label className="labelText">Payout Date</label>
                          </div>
                          <div className="flex flex-col">
                            <p className="text-sm detailtext min-h-[20px]">
                              {data.payout?.payout_request?.payout_date ? moment(data.payout?.payout_request?.payout_date).format("DD-MM-YYYY") : ""}
                            </p>
                          </div>
                        </div>
                        <div className="col-span-1 ">
                          <div className="flex flex-col">
                            <label className="labelText">Payout Status</label>
                          </div>
                          <div className="flex flex-col">
                            <p className="text-sm detailtext min-h-[20px]">
                            {data.payout?.payout_request?.status ?? "--"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                )
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
