import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";

import CustomBreadCrumbs from "../../components/breadcrumbs/index";
import Tabs from "../../customComponents/Tabs/Tabs";
import { useDataFieldStore } from "../../store/datafields";
import Favourite from "../../components/favourite/index";
import moment from "moment";
import { getPayoutDetails } from "./apis/api.actions";
import { capitalize } from "../../utilities/format";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function PayoutDetailedView(props: any) {
  const { datafieldsItem, setDataFieldsitem } = useDataFieldStore();
  const navigate = useNavigate();
  const params = useParams();

  const [tab, setTab] = useState("payoutDetails");
  const [data, setData] = useState<any>([]);
  const [favouriteData, setFavoriteData] = useState<any>([]);

  const [payoutkId, setPayoutId] = useState(params.id);
  const tabs = [
    {
      label: "Detailed Information",
      value: "payoutDetails",
      tagText: "Detailed Information",
    },
  ];

  const handleProjectLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    if (row.milestone?.work?.id) {
      setDataFieldsitem({
        name: row.milestone?.work?.title,
        key: row.milestone?.work?.id,
      });

      navigate(`/project/${row.milestone?.work?.id}`);
    }
  };

  const handlePayoutToLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
          if (row?.milestone?.company)
          {
            setDataFieldsitem({
              name: row?.milestone?.company?.name,
              key: row?.milestone?.company?.id,
            });
      
            navigate(`/company/${row?.milestone?.company?.id}`);
          } else {
            setDataFieldsitem({
              name: row?.milestone?.created_by?.name,
              key: row?.milestone?.created_by?.id,
            });
      
            navigate(`/user/${row?.milestone?.created_by?.id}`);
          }
  };

  const getData = (id: any) => {
    getPayoutDetails(id).then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    getData(payoutkId);
  }, []);
  const dummyImage =
    "https://img.freepik.com/premium-vector/people-profile-graphic_24911-21373.jpg";

    let payoutEntity = null;
    if (data?.milestone?.company)
    {
      payoutEntity = data?.milestone?.company
    } else {
      payoutEntity = data?.milestone?.created_by
    }

  return (
    <div>
      <CustomBreadCrumbs
        data={[
          { title: "Payouts", link: "/payout-list" },
          { title: data?.display_id ?? "--" },
        ]}
      />
      <div className=" bg-bgWhite customCardContainer !shadow-none !rounded-lg flex flex-col flex-1">
        <Tabs
          ariaLabel="consultation tabs"
          setTab={setTab}
          tab={tab}
          tabs={tabs}
        />
        {tab == "payoutDetails" && (
          <div className="content tabContent ">
            <div className="flex flex-col">
              <div className="p-1.5 px-3 flex items-center justify-start w-full bg-tableHeader">
                <p className="text-sm font-semibold">Payout Details</p>
              </div>
              <div className="grid gap-4 p-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Request ID</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data?.display_id ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Request Date</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.created_at
                        ? moment(data.created_at).format("DD-MM-YYYY")
                        : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Project ID</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.milestone?.work?.display_id ? (
                        (<Link className="hover:underline text-[#1650e2]" to={``} onClick={(e) => handleProjectLink(e, data)}>
                        {data.milestone?.work?.display_id ?? "--"}
                      </Link>)
                      ) : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Project Name</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.milestone?.work?.title ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Milestone ID</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.milestone?.display_id ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Milestone Name</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.milestone?.name ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Milestone Amount</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.amount ? `OMR ${data.amount}` : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Payout To</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {payoutEntity ? (
          <Link className="hover:underline text-[#1650e2]" to={``} onClick={(e) => handlePayoutToLink(e, data)}>
            {payoutEntity?.display_id}
          </Link>
        ) : ""}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Payout Settled On</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.payout_date
                        ? moment(data.payout_date).format("DD-MM-YYYY")
                        : "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Payout Status</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                      {data.status ?? "--"}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <div className="flex flex-col">
                    <label className="labelText">Transaction ID</label>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm detailtext min-h-[20px]">
                    {data.transaction_id ?? "--"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
