import { zodResolver } from "@hookform/resolvers/zod";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import Modal from "../../customComponents/prompt";
import CustomTable from "../../customComponents/table/table";
import { useDataFieldStore } from "../../store/datafields";
import { useLoaderStore } from "../../store/loaderStore";
import { getReviewList } from "./apis/api.actions";
import DeleteProjectReview from "./components/deleteReview";

type PicklistData = {
  nameMultiLang: {
    name?: string;
    lang?: string;
  }[];
  title?: string;
  description?: string;
};
export default function ProjectReviewsSuperAdmin(props: any) {
  const [data, setData] = useState<any>([]);
  const { setDataFieldsitem } = useDataFieldStore();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState<any>(25);
  const [newPage, setNewPage] = useState<any>(1);
  const [totalCount, setTotalCount] = useState<any>();
  const [searchValue, setSearchValue] = useState<string | undefined>("");
  const [pickListItemData, setPickLsitItemData] = useState<PicklistData>();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [detailId, seDetailId] = useState<string>("");
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<any>([]);
  const [reviewId, setReviewId] = useState<string>("");
  const [reviewDeleteConfirmation, setReviewDeleteConfirmation] =
    useState(false);

  const handleLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    setDataFieldsitem({ name: row.id, key: row.id });
    navigate(`/review/${row.id}`);
  };

  const handleProjectLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    if (row.work?.id) {
      setDataFieldsitem({
        name: row.work?.title,
        key: row.work?.id,
      });

      navigate(`/project/${row.work?.id}`);
    }
  };

  const handleReviewerLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    if (row.user?.id) {
      setDataFieldsitem({
        name: row.user?.name,
        key: row.user?.id,
      });

      navigate(`/user/${row.user?.id}`);
    }
  };

  const handleRecipientLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    if (row.recipient?.id) {
      setDataFieldsitem({
        name: row.recipient?.name,
        key: row.recipient?.id,
      });

      navigate(`/user/${row.recipient?.id}`);
    }
  };

  const columns = [
    {
      title: "Project ID",
      field: "work_id",
      type: "string",
      render: (row: any) => (
        <Link to={``} onClick={(e) => handleProjectLink(e, row)}>
          {row.work?.display_id}
        </Link>
      ),
    },
    {
      title: "Review By",
      field: "user_type",
      type: "string",
      textStyle: false,
      render: (row: any) => (
        <span>{row?.user_type === "Owner" ? "Owner" : "Consultant"}</span>
      ),
    },
    {
      title: "Reviewer",
      field: "user_name",
      type: "string",
      render: (row: any) => (
        <Link to={``} onClick={(e) => handleReviewerLink(e, row)}>
          {row.user?.name}
        </Link>
      ),
    },
    {
      title: "Recipient",
      field: "recipient_name",
      type: "string",
      render: (row: any) => (
        <Link to={``} onClick={(e) => handleRecipientLink(e, row)}>
          {row.recipient?.name}
        </Link>
      ),
    },
    {
      title: "Rating Given",
      field: "rating",
      type: "number",
      textStyle: false,
      align: "right",
      render: (row: any) => (
        <Link className="hover:underline text-[#1650e2]" to={``} onClick={(e) => handleLink(e, row)}>
          {row?.rating}/5
        </Link>
      ),
    },
    {
      title: "Review",
      field: "comment",
      type: "string",
    },
    {
      title: "Review Date",
      field: "created_at",
      type: "date",
    },
  ];

  const { setLoader, loader } = useLoaderStore();
  const { enqueueSnackbar } = useSnackbar();
  const getDataFields = (
    rows_per_page: number,
    new_page: number,
    search?: string
  ) => {
    setLoader(true);
    getReviewList(rows_per_page, new_page, search ?? searchValue)
      .then((res) => {
        setTotalCount(res.total);
        setData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    getDataFields(rowsPerPage, newPage);
  }, []);

  const handleRefresh = () => {
    getDataFields(rowsPerPage, newPage);
  };

  const handlePagination = (new_page: number, rows_per_page: number) => {
    setNewPage(new_page);
    setRowsPerPage(rows_per_page);
    getDataFields(rows_per_page, new_page);
  };

  const handleGlobalSearch = (search?: string) => {
    setNewPage(1)
    setSearchValue(search);
    getDataFields(rowsPerPage, 1, search);
  };

  let actionProps = [
    {
      actionTitle: "View",
      action: (data: any) => navigate(`/review/${data.id}`),
      actionIcon: "view",
    },
    {
      actionTitle: "Delete",
      action: (data: any) => handleDelete(data),
      actionIcon: "block",
    },
  ];

  const handleDelete = (data: any) => {
    setReviewId(data?.id);
    setReviewDeleteConfirmation(true);
  };

  const handleCreate = () => {
    setPickLsitItemData({
      nameMultiLang: [{ name: "", lang: "en" }],
      title: "",
      description: "",
    });
    setEdit(false);
    setOpen(true);
  };

  const handleEdit = (res: any) => {
    const result: any = [];
    seDetailId(res?.id);
    result.push({
      title: res?.title,
      description: res?.description,
      lang: "en",
    });
    setPickLsitItemData({ nameMultiLang: result, ...res });
    setEdit(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteModal = (data: any) => {
    setModalOpen(true);
    setDeleteData(data);
  };

  const handleDeleteReviewConfirmationClose = (refreshList?: boolean) => {
    setReviewDeleteConfirmation(false);
    setReviewId("");
    if (refreshList === true) {
      getDataFields(rowsPerPage, newPage, searchValue);
    }
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTable
            data={data}
            columns={columns}
            title={"Reviews & Ratings"}
            maxBodyHeight="calc(100Vh - 237px)"
            minBodyHeight="calc(100Vh - 237px)"
            search={true}
            pagination={true}
            customFilter={false}
            handlePagination={handlePagination}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            isLoading={loader}
            page={newPage}
            searchValue={searchValue}
            actionProps={actionProps}
            handleGlobalSearch={handleGlobalSearch}
          />
        </Grid>
      </Grid>
      <DeleteProjectReview
          open={reviewDeleteConfirmation}
          reviewId={reviewId}
          handleClose={handleDeleteReviewConfirmationClose}
        />
    </div>
  );
}
