import { create } from "zustand";

export const useLoaderStore = create<Items>((set) => ({
  loader: false,
  setLoader: (input) => set({ loader: input }),
}));
type Items = {
  loader: boolean;
  setLoader: (value: boolean) => void;
};
