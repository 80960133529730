import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import CustomTable from "../../customComponents/table/table";
import { useDataFieldStore } from "../../store/datafields";
import { useLoaderStore } from "../../store/loaderStore";
import { FilterDataType } from "../../customComponents/table/components/commontypes";
import moment from "moment";
import { changeDisputeStatus, getDisputeList } from "./apis/api.actions";
import CustomDialogBox from "../../customComponents/dialogbox";
import { disputeSchema } from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import TextArea from "../../customComponents/textfield/textarea";
import SelectField from "../../customComponents/selectfield";

export default function Disputes(props: any) {
  const [data, setData] = useState<any>([]);
  const { setDataFieldsitem } = useDataFieldStore();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState<any>(25);
  const [newPage, setNewPage] = useState<any>(1);
  const [totalCount, setTotalCount] = useState<any>();
  const [searchValue, setSearchValue] = useState<string | undefined>("");
  const [appliedFilters, setAppliedFilters] = useState("");
  const [filterData, setFilterData] = useState<FilterDataType>();
  const [showChangeStatustPopup, setShowChangeStatustPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [statusList, setStatusList] = useState([
    {id: "Processing", label: "Processing"},
  ]);
  const disputeTypes = [
    { id: "Cancel", label: "Cancel" },
    { id: "General", label: "General" },
    { id: "Milestone", label: "Milestone" },
  ];
  const disputeStatuses = [
    { id: "Active", label: "Active" },
    {id: "Processing", label: "Processing"},
    {id: "Resolved", label: "Resolved"},
    {id: "Cancelled", label: "Cancelled"},
  ];
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setError,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      'dispute_status': "",
      'comment': "",
    },
    resolver: zodResolver(disputeSchema),
    mode: "onChange",
  });

  const handleLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    setDataFieldsitem({
      name: `${row.display_id}`,
      key: row.id,
    });
    navigate(`/dispute/${row.id}`);
  };

  const handleProjectLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    if (row.work?.id) {
      setDataFieldsitem({
        name: row.work?.title,
        key: row.work?.id,
      });

      navigate(`/project/${row.work?.id}`);
    }
  };

  const columns = [
    {
        title: "Dispute Ticket ID",
        field: "display_id",
        type: "string",
        minWidth: 120,
        render: (row: any) => (
          <Link to={``} onClick={(e) => handleLink(e, row)}>
            {row.display_id}
          </Link>
        ),
      },
      {
        title: "Dispute Type",
        field: "dispute_type",
        type: "string",
        minWidth: 120,
      },
    {
      title: "Project ID",
      field: "work",
      type: "object",
      objectKey: "display_id",
      minWidth: 120,
      render: (row: any) => {
        return row?.work?.work_type?.slug === "gig" ? (<Link to={``} onClick={(e) => handleProjectLink(e, row)}>
          {row.work?.display_id}
        </Link>) : (
          <span>{row.work?.display_id}</span>
        )
      },
    },
    {
      title: "Milestone ID",
      field: "bid_milestone",
      type: "object",
      objectKey: "display_id",
      minWidth: 120,
    },
    {
      title: "Milestone Title",
      field: "bid_milestone",
      type: "object",
      objectKey: "name",
      minWidth: 120,
    },
    {
      title: "Milestone Status",
      field: "bid_milestone",
      type: "object",
      objectKey: "milestone_status",
      minWidth: 120,
    },
    {
      title: "Dispute Status",
      field: "dispute_status",
      type: "string",
      minWidth: 120,
    },
    {
      title: "Raised Date",
      field: "created_at",
      type: "date",
      minWidth: 120,
    },
    {
      title: "Closed Date",
      field: "resolved_at",
      type: "date",
      minWidth: 120,
    },
    {
      title: "Reason",
      field: "dispute_reason",
      type: "object",
      objectKey: "name",
      minWidth: 120,
    },
    {
      title: "Raised By",
      field: "raised_by",
      type: "object",
      objectKey: "work_relation",
      minWidth: 120,
    },
  ];

  let actionProps = [
    {
      actionTitle: "View",
      action: (data: any) => navigate(`/dispute/${data.id}`),
      actionIcon: "view",
    },
    {
      actionTitle: "Change Status",
      action: handleChangeStatus,
      actionIcon: "edit",
      show: (row: any) => !(row?.dispute_status === "Cancelled" || row?.dispute_status === "Resolved")
    },
  ];

  function handleChangeStatus(data: any)
  {
    setSelectedRow(data)
    if (data.dispute_status === "Processing")
    {
      setStatusList([
        {id: "Cancelled", label: "Cancelled"},
        {id: "Resolved", label: "Resolved"},
      ])
    } else {
      setStatusList([
        {id: "Processing", label: "Processing"},
      ])
    }
    setShowChangeStatustPopup(true)
  }

  const { setLoader, loader } = useLoaderStore();
  const { enqueueSnackbar } = useSnackbar();
  const getDataFields = (
    rows_per_page: number,
    new_page: number,
    search?: string
  ) => {
    setLoader(true);
    getDisputeList(
      rows_per_page,
      new_page,
      search ?? searchValue,
      appliedFilters
    )
      .then((res) => {
        setTotalCount(res.total);
        setData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    setNewPage(1);
    getDataFields(rowsPerPage, 1);
  }, [appliedFilters]);

  const handlePagination = (new_page: number, rows_per_page: number) => {
    setNewPage(new_page);
    setRowsPerPage(rows_per_page);
    getDataFields(rows_per_page, new_page);
  };

  const handleGlobalSearch = (search?: string) => {
    setNewPage(1)
    setSearchValue(search);
    getDataFields(rowsPerPage, 1, search);
  };

  useEffect(() => {
    setFilterData({
      primary: [
        {
          title: "Dispute Type",
          field: "dispute_type",
          type: "select",
          options: disputeTypes,
          multiple: true,
          value: [],
        },
        {
          title: "Dispute Status",
          field: "dispute_status",
          type: "select",
          options: disputeStatuses,
          multiple: true,
          value: [],
        },
        {
          title: "Created At",
          field: "created_at",
          type: "daterange",
          value: [],
          maxDate: new Date(),
        },
      ],
      secondary: [],
    });
  }, []);

  function handleFilterSubmit(values: FilterDataType) {
    let disputeTypeVal =
      values.primary.find((filter) => filter.field === "dispute_type")?.value ??
      null;
    if (Array.isArray(disputeTypeVal)) {
      disputeTypeVal = disputeTypeVal.join();
    }
    let disputeStatusVal =
      values.primary.find((filter) => filter.field === "dispute_status")?.value ??
      null;
    if (Array.isArray(disputeStatusVal)) {
      disputeStatusVal = disputeStatusVal.join();
    }
    let createdAtVal =
      values.primary.find((filter) => filter.field === "created_at")?.value ??
      [];
    if (
      Array.isArray(createdAtVal) &&
      createdAtVal[0] &&
      createdAtVal[1] &&
      moment(createdAtVal[0]).isValid() &&
      moment(createdAtVal[1]).isValid()
    ) {
      createdAtVal = `${moment(createdAtVal[0]).format(
        "YYYY-MM-DD"
      )} - ${moment(createdAtVal[1]).format("YYYY-MM-DD")}`;
    } else {
      createdAtVal = "";
    }
    let filterString = "";
    filterString = `${
      disputeTypeVal ? `&filter[dispute_type]=${disputeTypeVal}` : ""
    }${
      disputeStatusVal ? `&filter[dispute_status]=${disputeStatusVal}` : ""
    }${
      createdAtVal.length > 0 ? `&filter[created_at]=${createdAtVal}` : ""
    }`;
    setAppliedFilters(filterString);
  }

  function handleChangeStatusPopupClose() {
    setShowChangeStatustPopup(false);
    setSelectedRow(undefined);
    setStatusList([
      {id: "Processing", label: "Processing"},
    ]);
    reset();
  }

  function handleChangeStatusConfirmed()
  {
    handleSubmit(handleValidatedData)();
  }

  function handleValidatedData(formData: any) {
    let payload: any = {
      id: selectedRow.id,
      dispute_status: formData?.dispute_status,
      comment: formData?.comment,
    };

    changeDisputeStatus(payload)
    .then((res) => {
      getDataFields(rowsPerPage, newPage);
      enqueueSnackbar(res.message, {
        variant: res.status === true ? "success" : "error",
      });
      handleChangeStatusPopupClose();
    })
    .catch((err) => {
      enqueueSnackbar(err?.response?.data?.message, {
        variant: "error",
      });
    });
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTable
            data={data}
            columns={columns}
            title={"Dispute Tickets"}
            maxBodyHeight="calc(100Vh - 237px)"
            minBodyHeight="calc(100Vh - 237px)"
            search={true}
            pagination={true}
            customFilter={true}
            filterData={filterData}
            filterSubmit={handleFilterSubmit}
            isLoading={loader}
            handlePagination={handlePagination}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            page={newPage}
            actionProps={actionProps}
            searchValue={searchValue}
            handleGlobalSearch={handleGlobalSearch}
          />
        </Grid>
      </Grid>
      <CustomDialogBox
        open={showChangeStatustPopup}
        title="Change Dispute Status"
        handleClose={handleChangeStatusPopupClose}
        primaryButton={{
          title: "Update",
          action: handleChangeStatusConfirmed,
        }}
        secondaryButton={{
          title: "Cancel",
          action: handleChangeStatusPopupClose,
        }}
        maxWidth="lg"
      >
        <div className="grid grid-cols-1 gap-4 p-2">
          <div className="col-start-1 col-span-1">
          <Controller
            name="comment"
            control={control}
            render={({ field }) => {
              return (
                <TextArea
                  label="Comment"
                  required={true}
                  error={errors?.comment?.message}
                  {...field}
                />
              );
            }}
          />
          </div>
          <div className="col-start-1 col-span-1">
          <Controller
            name="dispute_status"
            control={control}
            render={({ field }) => {
              return (
                <SelectField
                  label="Dispute Status"
                  required={true}
                  data={statusList}
                  error={errors?.dispute_status?.message}
                  {...field}
                />
              );
            }}
          />
          </div>
        </div>
      </CustomDialogBox>
    </div>
  );
}
