import { debounce } from "@mui/material";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useState } from "react";

import Filter from "../../../components/filter/filter";
import CustomButton from "../../button/index";
import DateRangePicker from "../../dateRangePicker";
import SearchField from "../../textfield/searchField";
import { FilterDataType, FilterItem } from "./commontypes";
import FilterComponent from "./filter";
import FilterComponents from "./filterComponents";

interface EnhancedTableToolbarProps {
  title?: string;
  search?: boolean;
  customFilter?: boolean;
  filterData?: FilterDataType;
  filterSubmit?: (values: FilterDataType) => void;
  filterClear?: () => void;
  searchValue?: string;
  handleGlobalSearch?: (search?: string) => void;
  secondaryFields: FilterItem[];
  fields: FilterItem[];
  handleSubmit?: any;
  addButton?: any;
  exportButton?: any;
  customButtons?: any[];
  dateFilter?: boolean;
  handleDateFilter?: (data: any) => void;
  dateFilterKeys?: any;
}

const CustomTableToolbar = (props: EnhancedTableToolbarProps) => {
  const {
    title,
    search,
    searchValue,
    customFilter,
    filterData,
    fields,
    filterSubmit,
    filterClear,
    handleGlobalSearch,
    secondaryFields,
    handleSubmit,
    addButton,
    exportButton,
    customButtons,
    dateFilter,
    handleDateFilter,
    dateFilterKeys,
  } = props;
  const [searchKey, setSearchKey] = useState<string | undefined>(searchValue);
  const filterProps = {
    filterData,
    filterSubmit,
    filterClear,
    handleSubmit,
    fields,
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchKey(e.target.value);
    handleSearch(e.target.value);
  };
  const handleSearch = debounce((value?: string) => {
    handleGlobalSearch && handleGlobalSearch(value);
  }, 1000);
  const handleDelete = () => {};
  const handleClear = () => {
    setSearchKey("");
    handleSearch("");
  };
  return (
    <Toolbar className="min-h-min p-3 border-b border-cardBorder ">
      <Grid container>
        <Grid item xs={12}>
          <Grid className="flex items-center justify-between gap-x-3">
            <Typography
              variant="h6"
              id="tableTitle"
              className="!text-base font-semibold flex-1"
              component="div"
            >
              {title}
            </Typography>
            {secondaryFields &&
              secondaryFields?.map((item, index) => (
                <div key={item.title} className="px-1">
                  <FilterComponents
                    data={item}
                    path={`filterData.${index}`}
                    hideLabel={true}
                  />
                </div>
              ))}
            {search && (
              <div className="px-1">
                <SearchField
                  label=""
                  value={searchKey}
                  placeholder={"Search"}
                  search={true}
                  onChange={(e) => handleChange(e)}
                  handleClear={handleClear}
                />
              </div>
            )}
            {dateFilter && (
              <div className=" w-[280px]">
                <DateRangePicker
                  onChange={handleDateFilter && handleDateFilter}
                  value={dateFilterKeys?.value ?? [null, null]}
                  maxDate={dateFilterKeys?.maxDate}
                />
              </div>
            )}
            {false && <Filter />}
            {addButton && (
              <CustomButton
                title={addButton.title}
                onClick={() => addButton?.action()}
              />
            )}
            {exportButton && (
              <CustomButton
                className="h-12 "
                title={exportButton.title}
                tooltipText={exportButton.tooltipText}
                onClick={() => exportButton?.action()}
              />
            )}
            {customButtons &&
              customButtons.length > 0 &&
              customButtons.map((btnItem) => (
                <CustomButton
                  className="h-12 "
                  title={btnItem?.title}
                  tooltipText={btnItem?.tooltipText}
                  onClick={() => btnItem?.action()}
                />
              ))}
            {customFilter && <FilterComponent {...filterProps} />}
          </Grid>
        </Grid>
        {filterData && false && (
          <Stack
            direction="row"
            className="flex-wrap	flex items-center"
            spacing={1}
          >
            <Typography
              variant="h6"
              id="tableTitle"
              className="text-sm mr-2"
              component="div"
            >
              Applied Filters:
            </Typography>
            <Chip
              label="Clickable Deletable"
              className="h-fit text-xs m-1"
              onDelete={handleDelete}
            />
          </Stack>
        )}
      </Grid>
    </Toolbar>
  );
};
export default CustomTableToolbar;
