import { VscEmptyWindow } from "react-icons/vsc";
type Props = {
  emptyMsg?: string;
};
export default function NoDataFound(props: Props) {
  const { emptyMsg } = props;
  return (
    <div className="justify-center h-full flex align-middle col-auto mt-10">
      <div className="block">
        <VscEmptyWindow className=" text-5xl text-noData m-auto" />
        <p className="text-secondary text-sm">
          {emptyMsg ? emptyMsg : "No Records Found"}
        </p>
      </div>
    </div>
  );
}
