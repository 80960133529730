import { zodResolver } from "@hookform/resolvers/zod";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import Modal from "../../customComponents/prompt";
import CustomTable from "../../customComponents/table/table";
import { useDataFieldStore } from "../../store/datafields";
import { useLoaderStore } from "../../store/loaderStore";
import { getAddonList } from "./apis/api.actions";

type PicklistData = {
  nameMultiLang: {
    name?: string;
    lang?: string;
  }[];
  title?: string;
  description?: string;
};
export default function Addons(props: any) {
  const [data, setData] = useState<any>([]);
  const { setDataFieldsitem } = useDataFieldStore();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState<any>(25);
  const [newPage, setNewPage] = useState<any>(1);
  const [totalCount, setTotalCount] = useState<any>();
  const [searchValue, setSearchValue] = useState<string | undefined>("");
  const [pickListItemData, setPickLsitItemData] = useState<PicklistData>();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [detailId, seDetailId] = useState<string>("");
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<any>([]);

  const handleLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    setDataFieldsitem({ name: row.title, key: row.id });
    navigate(`/addon/${row.id}`);
  };

  const columns = [
    {
      title: "Title",
      field: "title",
      type: "string",
      render: (row: any) => (
        <Link to={``} onClick={(e) => handleLink(e, row)}>
          {row.addon_type?.title}
        </Link>
      ),
    },
    {
      title: "Cost",
      field: "price",
      type: "string",
      align: "right",
      textStyle: false,
      render: (row: any) => (
        <span>{row?.currency ?? ""} {row?.price}</span>
      ),
    },
    {
      title: "Total Sold",
      field: "total_sold",
      type: "number",
    },
    {
      title: "Total Revenue",
      field: "total_revenue",
      type: "string",
      align: "right",
      textStyle: false,
      render: (row: any) => (
        <span>{row?.currency ?? ""} {row?.total_revenue}</span>
      ),
    },
    {
      title: "Created At",
      field: "created_at",
      type: "date",
    },
    {
      title: "Updated At",
      field: "updated_at",
      type: "date",
    },
  ];

  const { setLoader, loader } = useLoaderStore();
  const { enqueueSnackbar } = useSnackbar();
  const getDataFields = (
    rows_per_page: number,
    new_page: number,
    search?: string
  ) => {
    setLoader(true);
    getAddonList(rows_per_page, new_page, search ?? searchValue)
      .then((res) => {
        setTotalCount(res.total);
        setData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    getDataFields(rowsPerPage, newPage);
  }, []);

  const handleRefresh = () => {
    getDataFields(rowsPerPage, newPage);
  };

  const handlePagination = (new_page: number, rows_per_page: number) => {
    setNewPage(new_page);
    setRowsPerPage(rows_per_page);
    getDataFields(rows_per_page, new_page);
  };

  const handleGlobalSearch = (search?: string) => {
    setNewPage(1)
    setSearchValue(search);
    getDataFields(rowsPerPage, 1, search);
  };

  const handleCreate = () => {
    setPickLsitItemData({
      nameMultiLang: [{ name: "", lang: "en" }],
      title: "",
      description: "",
    });
    setEdit(false);
    setOpen(true);
  };

  const handleEdit = (res: any) => {
    const result: any = [];
    seDetailId(res?.id);
    result.push({
      title: res?.title,
      description: res?.description,
      lang: "en",
    });
    setPickLsitItemData({ nameMultiLang: result, ...res });
    setEdit(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteModal = (data: any) => {
    setModalOpen(true);
    setDeleteData(data);
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTable
            data={data}
            columns={columns}
            title={"Add-ons"}
            maxBodyHeight="calc(100Vh - 237px)"
            minBodyHeight="calc(100Vh - 237px)"
            search={true}
            pagination={true}
            customFilter={false}
            handlePagination={handlePagination}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            isLoading={loader}
            page={newPage}
            searchValue={searchValue}
            handleGlobalSearch={handleGlobalSearch}
          />
        </Grid>
      </Grid>
    </div>
  );
}
