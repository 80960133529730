import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

type Props = {
  label?: string;
  onChange?: (data: any) => void;
  value?: any;
  minDate?: Date;
  maxDate?: Date;
  error?: any;
};

function DateRangePicker(props: Props) {
  const { label, error, onChange, value, maxDate, minDate } = props;
  const [dateRange, setDateRange] = useState(value);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    setDateRange(value);
  }, [value]);

  return (
    <div className="relative flex flex-col">
      {label && (
        <div className="labelText mb-1.5 field-label text-left">{label}</div>
      )}

      <DatePicker
        selectsRange={true}
        startDate={startDate}
        className="relative w-full input-field rounded-lg px-2.5 py-2 h-9 input-placeholder text-sm border border-[#000] border-opacity-20 outline-none"
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={"dd-MM-yyyy"}
        //  popperContainer={CalendarContainer}
        onChange={(update: any) => {
          setDateRange(update);
          onChange && onChange(update);
        }}
      />
    </div>
  );
}

export default DateRangePicker;
