import DateRangePicker from "../../dateRangePicker";
import CustomDatePicker from "../../datepicker/index";
import SelectField from "../../selectfield/index";
import CustomInput from "../../textfield/index";
import { useFormContext, Controller } from "react-hook-form";

type Props = {
  data?: any;
  path?: string;
  hideLabel?: boolean;
};
export default function FilterComponents(props: Props) {
  const { register, control } = useFormContext();

  const { data, path, hideLabel } = props;
  switch (data?.type) {
    case "date":
      return (
        <Controller
          name={`${path}.value`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomDatePicker
              value={value ?? null}
              fullWidth
              placeholder={data?.title}
              handleDateChange={(newValue) => onChange(newValue)}
              label={hideLabel ? "" : data?.title}
              name={`${path}.value`}
            />
          )}
        />
      );
    case "daterange":
      return (
        <Controller
          name={`${path}.value`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <DateRangePicker
              value={value ?? null}
              onChange={(newValue: any) => onChange(newValue)}
              label={hideLabel ? "" : data?.title}
              maxDate={data?.maxDate ?? null}
            />
          )}
        />
      );
    case "number":
    case "text":
      return (
        <CustomInput
          label={hideLabel ? "" : data?.title}
          type={data.type}
          //   value={data?.value}
          placeholder={data?.title}
          fullWidth
          name={`${path}.value`}
          register={register(`${path}.value`)}
        />
      );
    case "selects":
      return (
        <SelectField
          onChange={() => {}}
          fullWidth
          placeholder={data?.title}
          register={register(`${path}.value`)}
          label={hideLabel ? "" : data?.title}
          data={data?.options}
        />
      );
    case "select":
      return (
        <Controller
          control={control}
          name={`${path}.value`}
          render={({ field: { onChange, ...otherFieldProps } }) => {
            return (
              <SelectField
                onChange={(value) => {
                  onChange(value);
                  if (data.handleChange) {
                    data.handleChange(value);
                  }
                }}
                {...otherFieldProps}
                fullWidth
                data={data?.options}
                placeholder={data?.title}
                label={hideLabel ? "" : data?.title}
                multiple={data?.multiple ?? false}
              />
            );
          }}
        />
      );
    default:
      return null;
  }
}
