import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
type Props = {
  data: {
    link?: string;
    title?: string;
  }[];
};
export default function CustomBreadCrumbs(props: Props) {
  const { data } = props;
  return (
    <Stack spacing={2} className="mb-2">
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {data.map((items) => (
          <div key={items.title}>
            {items.link ? (
              <Link
                key="1"
                color="inherit"
                to={items.link}
                className="text-xs font-medium hover:text-primary text-[#777777]"
              >
                {items.title}
              </Link>
            ) : (
              <a className="text-primary font-medium text-xs ">{items.title}</a>
            )}
          </div>
        ))}
      </Breadcrumbs>
    </Stack>
  );
}
