import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import CustomTable from "../../customComponents/table/table";
import { useDataFieldStore } from "../../store/datafields";
import { useLoaderStore } from "../../store/loaderStore";
import { FilterDataType } from "../../customComponents/table/components/commontypes";
import moment from "moment";
import { getPurchaseList } from "./apis/api.actions";

export default function Purchases(props: any) {
  const [data, setData] = useState<any>([]);
  const { setDataFieldsitem } = useDataFieldStore();
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState<any>(25);
  const [newPage, setNewPage] = useState<any>(1);
  const [totalCount, setTotalCount] = useState<any>();
  const [searchValue, setSearchValue] = useState<string | undefined>("");
  const [appliedFilters, setAppliedFilters] = useState("");
  const [filterData, setFilterData] = useState<FilterDataType>();

  const handleLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    setDataFieldsitem({
      name: `${row.plan ? row.plan.title : row.addon?.addon_type?.title} (${row.purchased_item_type})`,
      key: row.id,
    });
    navigate(`/purchase/${row.id}`);
  };

  const handleEntityLink = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    row: any
  ) => {
    e.preventDefault();
    if (row.entity?.id) {
      setDataFieldsitem({
        name: row.entity?.name,
        key: row.entity?.id,
      });

      navigate(`/${row.purchased_by_type}/${row.entity?.id}`);
    }
  };

  const columns = [
    {
      title: "Purchased Date",
      field: "created_at",
      type: "date",
      minWidth: 120,
    },
    {
      title: "Purchased By",
      field: "entity",
      type: "object",
      objectKey: "name",
      minWidth: 200,
      render: (row: any) => (
        <Link to={``} onClick={(e) => handleEntityLink(e, row)}>
          {row.entity?.name}
        </Link>
      ),
    },
    {
      title: "Item Type",
      field: "purchased_item_type",
      type: "string",
      minWidth: 120,
    },
    {
        title: "Item Name",
        field: "plan",
        type: "object",
        objectKey: "title",
        minWidth: 120,
        render: (row: any) => (
          <Link to={``} onClick={(e) => handleLink(e, row)}>
            {row.plan ? row.plan.title : row.addon?.addon_type?.title} ({row.purchased_item_type})
          </Link>
        ),
      },
    {
      title: "Amount Paid",
      field: "price",
      type: "string",
      minWidth: 200,
      textStyle: false,
      render: (row: any) => (
        <p>{row.currency} {row.price}</p>
      ),
    },
    {
      title: "Status",
      field: "current_status",
      type: "string",
      minWidth: 120,
    },
  ];

  let actionProps = [
    {
      actionTitle: "View",
      action: (data: any) => navigate(`/purchase/${data.id}`),
      actionIcon: "view",
    },
  ];

  const { setLoader, loader } = useLoaderStore();
  const { enqueueSnackbar } = useSnackbar();
  const getDataFields = (
    rows_per_page: number,
    new_page: number,
    search?: string
  ) => {
    setLoader(true);
    getPurchaseList(
      rows_per_page,
      new_page,
      search ?? searchValue,
      appliedFilters
    )
      .then((res) => {
        setTotalCount(res.total);
        setData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        enqueueSnackbar(err.response.data.message, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    setNewPage(1);
    getDataFields(rowsPerPage, 1);
  }, [appliedFilters]);

  const handlePagination = (new_page: number, rows_per_page: number) => {
    setNewPage(new_page);
    setRowsPerPage(rows_per_page);
    getDataFields(rows_per_page, new_page);
  };

  const handleGlobalSearch = (search?: string) => {
    setNewPage(1);
    setSearchValue(search);
    getDataFields(rowsPerPage, 1, search);
  };

  useEffect(() => {
    setFilterData({
      primary: [
        {
          title: "Created At",
          field: "created_at",
          type: "daterange",
          value: [],
          maxDate: new Date(),
        },
      ],
      secondary: [],
    });
  }, []);

  function handleFilterSubmit(values: FilterDataType) {
    let createdAtVal =
      values.primary.find((filter) => filter.field === "created_at")?.value ??
      [];
    if (
      Array.isArray(createdAtVal) &&
      createdAtVal[0] &&
      createdAtVal[1] &&
      moment(createdAtVal[0]).isValid() &&
      moment(createdAtVal[1]).isValid()
    ) {
      createdAtVal = `${moment(createdAtVal[0]).format(
        "YYYY-MM-DD"
      )} - ${moment(createdAtVal[1]).format("YYYY-MM-DD")}`;
    } else {
      createdAtVal = "";
    }
    let filterString = "";
    filterString = `${
      createdAtVal.length > 0 ? `&filter[created_at]=${createdAtVal}` : ""
    }`;
    setAppliedFilters(filterString);
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTable
            data={data}
            columns={columns}
            title={"Plan & Add-on Sale"}
            maxBodyHeight="calc(100Vh - 237px)"
            minBodyHeight="calc(100Vh - 237px)"
            search={true}
            pagination={true}
            customFilter={true}
            filterData={filterData}
            filterSubmit={handleFilterSubmit}
            isLoading={loader}
            handlePagination={handlePagination}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            page={newPage}
            actionProps={actionProps}
            searchValue={searchValue}
            handleGlobalSearch={handleGlobalSearch}
          />
        </Grid>
      </Grid>
    </div>
  );
}
