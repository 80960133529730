import { ZodDate, z } from "zod";

export const disputeSchema = z.object({
  dispute_status: z
    .string({
      required_error: "Enter Dispute Status",
    })
    .trim()
    .min(1, { message: "Enter Dispute Status" }),
  comment: z
    .string({
      required_error: "Enter Comment",
    })
    .trim()
    .min(1, { message: "Enter Comment" }),
});
