import TableCell from "@mui/material/TableCell";
import moment from "moment";

export default function CustomTableCell({ col, row }: any) {
  switch (col.type) {
    case "object":
      return (
        <TableCell
        dir={col.dir ?? "ltr"}
        align={col.align ?? "left"}
          key={col.title}
          style={{
            maxWidth: col.maxWidth ?? 250,
            minWidth: col.minWidth ?? "initial",
          }}
          scope="row"
          className="tableCell h-1 truncate border border-formBorder border-r-0 border-b-0 !px-2.5 !py-2 !text-sm"
        >
          {row[col.field] && row[col.field][col.objectKey]
            ? row[col.field][col.objectKey]
            : ""}
        </TableCell>
      );
    case "date":
      return (
        <TableCell
          key={col.title}
          style={{
            maxWidth: col.maxWidth ?? 100,
            minWidth: col.minWidth ?? "initial",
          }}
          scope="row"
          className="tableCell h-1 truncate border border-formBorder border-r-0 border-b-0 !px-2.5 !py-2 !text-sm"
        >
          {row[col.field] ? moment(row[col.field]).format("DD-MM-YYYY") : ""}
        </TableCell>
      );
    case "number":
      return (
        <TableCell
          key={col.title}
          align="right"
          style={{
            maxWidth: col.maxWidth ?? 250,
            minWidth: col.minWidth ?? "initial",
          }}
          scope="row"
          className="tableCell h-1 truncate border border-formBorder border-r-0 border-b-0 !px-2.5 !py-2 !text-sm"
        >
          {row[col.field]}
        </TableCell>
      );
    default:
      return (
        <TableCell
          key={col.title}
          align={col.align ?? "left"}
          style={{
            maxWidth: col.maxWidth ?? 250,
            minWidth: col.minWidth ?? "initial",
          }}
          scope="row"
          className={`tableCell h-1 truncate border border-formBorder border-r-0 border-b-0 !px-2.5 !py-2 !text-sm ${row["highlightRow"] && row["highlightRow"] === true ? "bg-tabBorder" : ""}`}
        >
          {row[col.field]}
        </TableCell>
      );
  }
}
