import { useSnackbar } from "notistack";
import CustomDialogBox from "../../../customComponents/dialogbox/index";
import { deleteReview } from "../apis/api.actions";

type Props = {
  open: boolean;
  handleClose: (refreshList?: boolean) => void;
  reviewId: string;
};
const DeleteProjectReview = (props: Props) => {
  const { open, handleClose, reviewId } = props;
  const { enqueueSnackbar } = useSnackbar();

  const handleSave = () => {
    deleteReview(reviewId)
      .then((res) => {
        enqueueSnackbar(res.message, { variant: "success" });
        handleCloseDialog(true);
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      });
  };
  const handleCloseDialog = (refreshList?: boolean) => {
    handleClose(refreshList);
  };
  return (
    <div>
      <CustomDialogBox
        open={open}
        title={"Delete Review"}
        handleClose={handleCloseDialog}
        primaryButton={{
          title: "Delete",
          action: handleSave,
        }}
        secondaryButton={{
          title: "Cancel",
          action: () => handleCloseDialog(),
        }}
        className="mediumDialogBox"
      >
        <div className="flex flex-col gap-4 ">
          <p>
            Are you sure you want to delete the review?
          </p>
        </div>
      </CustomDialogBox>
    </div>
  );
};
export default DeleteProjectReview;
