import axios from "axios";

import { useAuthStore } from "../store/authStore";

let refreshTokenPromise: Promise<any> | null;

const serverApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

serverApi.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          if (!refreshTokenPromise) {
            refreshTokenPromise = axios
              .get(
                `${
                  process.env.REACT_APP_BASE_URL
                }/refresh-token?refresh_token=${localStorage.getItem(
                  "refreshToken"
                )}`
              )
              .then((rs) => {
                refreshTokenPromise = null; // clear state
                localStorage.setItem("accessToken", rs.data?.data?.token || "");
                serverApi.defaults.headers.common.Authorization = `Bearer ${rs.data?.data?.token}`;
                localStorage.setItem(
                  "refresh_token",
                  rs.data?.data?.refresh_token || ""
                );
                useAuthStore.setState({ authenticated: true });

                return serverApi;
              })
              .catch((err) => {
                if (err.response.status === 401) {
                  // localStorage.clear();
                  // window.open("/session-timeout", "_self");
                }
              });
          }

          return refreshTokenPromise.then((rs) => {
            err.config.headers[
              "Authorization"
            ] = `Bearer ${localStorage.getItem("accessToken")}`;
            return serverApi(err.config);
          });
        } catch (_error) {
          return Promise.reject(_error);
        }
      } else if (err.response.status === 401 && originalConfig._retry) {
        useAuthStore.setState({ authenticated: undefined });
        localStorage.removeItem("accessToken");
        return Promise.reject(err);
      }
      return Promise.reject(err);
    }
  }
);
serverApi.interceptors.request.use(
  (config: any) => {
    config.headers = config?.headers ?? {};

    // config.headers["Content-Type"] = "application/json";
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default serverApi;
