import "./fieldstyle.scss";

type Props = {
  label?: string;
  value?: string | number;
  onChange?: (e: any) => void;
  fullWidth?: boolean;
  required?: boolean;
  search?: boolean;
  name?: string;
  type?: string;
  register?: any;
  placeholder?: string;
  disabled?: boolean;
  lang?: string;
  error?: string;
  className?: string;
  multiline?: boolean;
};
export default function TextArea(props: Props) {
  const {
    label,

    name,
    register,
    required,
    error,
    lang,
    disabled,
    className,
  } = props;
  return (
    <div>
      {label && required ? (
        <label className=" justify-start labelText">
          {" "}
          {label} <span className="text-red">*</span>
        </label>
      ) : (
        <label className=" justify-start labelText"> {label}</label>
      )}

      <textarea
        {...props}
        name={name}
        className={`${className} customField text-sm border-2 border-[#000] border-opacity-20 rounded p-2 outline-none`}
        {...register}
        dir={lang === "ar" ? "rtl" : ""}
        lang={lang ?? "en"}
        disabled={disabled}
      ></textarea>
      {error && <span className="text-red text-xs">{error}</span>}
    </div>
  );
}
