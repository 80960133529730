import serverApi from "./core";

export const getData = (url: string, params?: any) =>
  serverApi.get(url, params).then((res) => res.data);

export const postData = (url: string, params?: any) =>
  serverApi.post(url, params).then((res) => res.data);
export const updateJsonData = (url: string, params?: any) =>
  serverApi
    .put(url, params, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
export const updateData = (url: string, params: any) =>
  serverApi.put(url, params).then((res) => res.data);

export const deleteData = (url: string) =>
  serverApi.delete(url).then((res) => res.data);
