import { getData, postData } from "../../../apis/api.helpers";
import apiUrl from "./api.url";

export const getDisputeList = (
  perPage: number,
  page: number,
  search?: string,
  filters?: string
) =>
  getData(
    `${apiUrl.DISPUTE_LIST}per_page=${perPage}&page=${page}&search=${search}${filters}`
  ).then((res) => res.data);

export const getDisputeDetails = (id: string) =>
  getData(`${apiUrl.DISPUTE_DETAILS}/${id}`).then((res) => res.data);

  export const changeDisputeStatus = (payload: any) => postData(apiUrl.CHANGE_DISPUTE_STATUS, payload);
