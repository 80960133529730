import { ZodDate, z } from "zod";

export const payoutSchema = z.object({
  transaction_id: z
    .string({
      required_error: "Enter Transaction ID",
    })
    .trim()
    .min(1, { message: "Enter Transaction ID" }),
  amount: z
    .string({
      required_error: "Enter Amount",
    })
    .trim()
    .min(1, { message: "Enter Amount" })
    .regex(new RegExp(/^\d*\.?\d*$/), {
      message: "Enter a valid amount",
    }),
  payout_date: z
  .string({
    required_error: "Enter Payout Date",
  })
  .trim()
  .min(1, { message: "Enter Payout Date" }),
});
